import { useState, useEffect } from "react";
import { APP_IMAGE_LOGO } from "../../images";
//import { useNavigate, useLocation } from "react-router-dom";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  Line,
  Svg,
} from "@react-pdf/renderer";
import MyCustomFonts from "../Fonts/Calibri Bold.TTF";
import MyCustomFontsOne from "../Fonts/Calibri Regular.ttf";
/* 
Font.register({
  family: "Calibri",
  fonts: [{ src: MyCustomFontsOne }],
}); */


const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
  },
  image: {
    display: "block",
    width: 160,
    height: 100,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 6,
  },
 
 
  title: {
    fontSize: 20,
    fontWeight: "900",
    paddingBottom: 10,
  },

  permTextTwo: {
    fontSize: 14,
    fontFamily: "Calibri",
    paddingBottom: 15,
    marginTop: 10,
  },

  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  lineView: {
    display: "block",
    height: "2%",
  },
});

const PdfResultInfo = () => {
 
  return (
    <>
      {/* Start of the document*/}

      <Document>
        {/*render a single page*/}

        <Page size='A4' wrap={true} style={styles.page}>
          <Image src={APP_IMAGE_LOGO} style={styles.image} />

          <Svg style={styles.lineView}>
            <Line
              x1='0'
              y1='0'
              x2='900'
              y2='0'
              strokeWidth={3}
              stroke='rgb(128, 128, 128)'
            />
          </Svg>

          <View>
            <Text style={styles.title}>METHODOLOGY TO GENERATE RESULT</Text>

            <View>
              <Text style={styles.permTextTwo}>
                The result is calculated on the basis of the confidence shown
                while giving rating. If the confidence or rating given is 3 or
                above for a particular task, associated with any of the specific
                seven sports volunteer roles the associated unique skills for
                that particular task belonging to the specific seven sports
                volunteer roles is generated in the calculated output.
              </Text>

              <Text style={styles.permTextTwo}>
                Depending on the level of agreement with the statements under the attribute section and if level of agreement is 3 or above certain personal qualities for the volunteer is calculated and displayed in the report.
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default PdfResultInfo;
