import { useState, useEffect } from "react";
import {
  APP_IMAGE_LOGO,
  APP_PDF_COVER_IMAGE,
  APP_PDF_FOOTER_IMAGE,
  APP_PDF_FOOTER_LOGO,
  PAGE_FOOTER_IMAGE,
} from "../../images";
//import { useNavigate, useLocation } from "react-router-dom";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  Line,
  Svg,
} from "@react-pdf/renderer";

import MyCustomFonts from "../Fonts/Calibri Bold.TTF";
import MyCustomFontsOne from "../Fonts/Calibri Regular.ttf";
import { translate } from "pdf-lib";
/* const attribute = ["Cheerful","Conscientious",
"Innovative","Motivated","Passionate about sport","Resilient","Sociable","Team worker","Use criticism constructively","Willing to take the initiative"] */

Font.register({
  family: "Calibri",
  fonts: [{ src: MyCustomFontsOne }, { src: MyCustomFonts }],
});

// Font.register({
//   family: "CalibriBold",
//   fonts: [{ src: MyCustomFonts}],
// });

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 100,
    // fontFamily: "Calibri",
    fontSize: 12,
  },
  bullet: {
    height: "100%",
  },
  viewOne: {
    justifyContent: "center",
  },
  appLogo: {
    display: "block",
    width: 130,
    height: 80,
    marginLeft: "auto",
    marginRight: "auto",
  },
  appTitle: {
    textAlign: "center",
    color: "#4a4a4a",
    fontSize: 22,
    fontWeight: "900",
    padding: "5px 0px 10px 0px",
  },
  documentTitle: {
    fontSize: 18,
    fontWeight: "900",
    padding: "10px 0px 10px 0px",
  },
  noteTextStyle: {
    fontSize: 12,
    fontFamily: "Calibri",
    padding: "5px 0px 5px 0px",
  },
  headingText2: {
    fontSize: 18,
    fontFamily: "Calibri",
    fontWeight: 900,
    paddingTop: 20,
    paddingBottom: 3,
  },
  headingText3: {
    fontSize: 12,
    fontFamily: "Calibri",
    paddingBottom: 5,
  },
  subHeadingText: {
    fontSize: 11,
    fontFamily: "Calibri",
    paddingBottom: 10,
  },
  tableHeading: {
    paddingTop: 30,
    borderBottom: 1,
    BorderStyle: "solid",
  },
  textTableSubtitle: {
    fontSize: 12,
    fontFamily: "Calibri",
    paddingBottom: 8,
  },
  queTile: {
    fontSize: 12,
    fontFamily: "Calibri",
    paddingLeft: 14,
    paddingBottom: 2,
  },
  skillTitle: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    fontFamily: "Calibri",
    paddingLeft: 10,
    marginVertical: 1,
  },
  heighlightStyle: {
    fontFamily: "Calibri",
    // color: "green",
    paddingRight: 3,
    fontSize: 13,
    fontWeight: 500,
  },
  roleTitle: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    fontFamily: "Calibri",
    paddingLeft: 10,
    paddingTop: 2,
  },
  column: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
  },
  row: {
    flexDirection: "row",
    marginTop: 5,
  },
  left: {
    width: "50%", //<- working alternative
  },
  right: {
    width: "50%", //<- working alternative
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  lineView: {
    display: "block",
    height: "2%",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    fontSize: 14,
    backgroundColor: "#7c91bb",
  },
  tableCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 12,
    fontFamily: "Calibri",
    paddingHorizontal: 5,
    paddingVertical: 3,
    textAlign: "center",
  },
  tableColOne: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 12,
    fontFamily: "Calibri",
    paddingHorizontal: 5,
    paddingVertical: 3,
  },
  tableColThird: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 12,
    fontFamily: "Calibri",
    paddingHorizontal: 4,
    paddingVertical: 3,
  },
  tableCell: {
    alignContent: "center",
    marginTop: 5,
    fontSize: 11,
  },
  tableCellHead: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableAttr: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  headerStyle: {
    top: "10pt",
    left: 0,
    position: "absolute",
    right: 0,
  },
  pageNumberStyle: {
    position: "absolute",
    bottom: "5",
    left: "50%",
    textAlign: "center",
    fontSize: 11,
    fontFamily: "Calibri",
  },
  pageHeaderStyle: {
    position: "absolute",
    top: "0.1",
    width: "120%",
    height: 40,
    backgroundColor: "#085791",
    justifyContent: "center",
  },
  headerText: {
    display: "flex",
    textAlign: "left",
    left: "5%",
    color: "white",
    fontSize: 16,
    fontWeight: "900",
  },
  contentHeadingStyle: {
    // width: "120%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 2,
    paddingTop: 3,
    backgroundColor: "#085791",
    justifyContent: "center",
  },
  contentHeaderText: {
    display: "flex",
    textAlign: "left",
    left: "1%",
    color: "white",
    fontSize: 14,
    fontWeight: "700",
  },
  pageContent: {
    marginTop: 40,
  },
  grayBlockStyle: {
    display: "flex",
    marginVertical: 10,
    backgroundColor: "#eaecf5",
    padding: 10,
    borderRadius: 10,
    border: "2px solid #eaecf5",
  },
  pageFooterImg: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rowItemStyle: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    fontWeight: 700,
    paddingLeft: 10,
    marginVertical: 1,
  },
});

const showPageHeader = (text) => {
  return (
    <View style={styles.pageHeaderStyle}>
      <Text style={styles.headerText}>{`+ ${text}`}</Text>
    </View>
  );
};

const showConentHeading = (text) => {
  return (
    <View style={styles.contentHeadingStyle}>
      <View style={{ width: "3%" }}>
        <Text style={styles.contentHeaderText}>{`+ `}</Text>
      </View>
      <View style={{ width: "95%", lineHeight: "1.3" }}>
        <Text style={styles.contentHeaderText}>{`${text}`}</Text>{" "}
      </View>
    </View>
  );
};

const lineBreak = () => {
  return (
    <Svg style={styles.lineView}>
      <Line
        x1="0"
        y1="0"
        x2="900"
        y2="0"
        strokeWidth={3}
        stroke="rgb(128, 128, 128)"
      />
    </Svg>
  );
};

const ListItem = ({ children }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        fontFamily: "Calibri",
        fontSize: 12,
        // marginTop: 4,
        lineHeight: "1.3",
        marginLeft: 10,
        fontWeight: 300,
      }}
    >
      <Text>{"\u2022" + " "}</Text>
      <Text>{children}</Text>
    </View>
  );
};

const Pdf = ({
  data,
  responedRolesList,
  allQuestion,
  sortedFormatedDataPillar,
  sortedAttributePillar,
  DataOne,
  DataTwo,
  skillBarChartUrl,
  attributebarChartUrl,
}) => {
  //const location = useLocation();
  //const data = location?.state;
  const [pdfData, setPdfData] = useState(data);
  const [skillOne, setSkillOne] = useState(DataOne);
  const [skillTwo, setSlillTwo] = useState(DataTwo);
  const [pillar, setPillar] = useState(allQuestion);
  const [attribute, setAttribute] = useState(sortedAttributePillar);
  const [formattedPillar, setFormattedPillar] = useState(
    sortedFormatedDataPillar
  );
  const [attributeFormatted, setAttributeFormatted] = useState(
    sortedAttributePillar
  );

  const isSkillGraphVisible = formattedPillar.length;
  const isAttributeGraphVisible = attribute.length;

  return (
    <>
      {/* Start of the document*/}

      <Document>
        {/*render a single page*/}

        <Page size="A4" wrap={true} style={styles.page}>
          {/* 1st page: Baner Image */}
          <Image style={{ height: "100%" }} src={APP_PDF_COVER_IMAGE} />

          {showPageHeader("FULL REPORT OF YOUR SPORT VOLUNTEERING PROFILE")}

          {/* 2nd page */}

          <View style={styles.pageContent}>
            {showConentHeading("ABOUT THIS REPORT")}
          </View>
          <View style={styles.grayBlockStyle}>
            <Text style={styles.noteTextStyle}>
              As a sport volunteer, you have carried out one or more important
              roles and demonstrated competencies, skills and
              attributes/personal qualities. It is important that you are aware
              of these so that you can use them to enhance your CV/resumé or
              help you in other ways to progress in employment or education.
            </Text>
            <Text style={[styles.skillTitle, styles.heighlightStyle]}>
              Below is a report which shows:
            </Text>
            <ListItem>The volunteering roles you have performed.</ListItem>
            <ListItem>The competencies you have demonstrated.</ListItem>
            <ListItem>
              The skills you are likely to have shown when carrying out these
              tasks.
            </ListItem>
            <ListItem>
              The attributes and personal qualities you have shown when
              volunteering.
            </ListItem>
          </View>

          {/* Sport volunteering role(s) */}
          {responedRolesList?.length && (
            <View>
              <View style={styles.pageContent}>
                {showConentHeading("SPORT VOLUNTEERING ROLE(S)")}
              </View>
              <View style={{ marginTop: 10 }}>
                <Text style={styles.headingText3}>
                  You have indicated that you have carried out the following
                  sport volunteering role(s):
                </Text>
                <View style={[styles.grayBlockStyle, { margin: 5 }]}>
                  {responedRolesList.map((role, index) => {
                    return (
                      <Text key={index} style={styles.roleTitle}>
                        {`SPORT VOLUNTEERING ROLE ${index + 1}: ${role.title}`}
                      </Text>
                    );
                  })}
                </View>
              </View>
            </View>
          )}

          {/* Sport volunteering role(s) */}

          {formattedPillar?.length && (
            <View break>
              <div>{showConentHeading("COMPETENCIES")}</div>

              <View style={{ marginTop: 10 }}>
                <Text style={styles.headingText3}>
                  You have told us that while working as a sport volunteer, you
                  can carry out the following tasks with a high level of
                  confidence (level 3 or 4) which suggests you are competent in
                  these tasks:
                </Text>
              </View>

              <View style={styles.grayBlockStyle}>
                {responedRolesList.map((task, taskIndex) => {
                  return (
                    <View style={{ marginBottom: 5 }}>
                      {task.question.length && (
                        <>
                          <Text
                            key={taskIndex}
                            style={[styles.skillTitle, styles.heighlightStyle]}
                          >
                            {` ${task.title} :`}
                          </Text>

                          <View style={{ marginLeft: 8 }}>
                            {task.question.map((que, queIndex) => {
                              return (
                                <Text key={queIndex} style={styles.queTile}>
                                  {`${queIndex <= 8 ? "0" : ""}${
                                    queIndex + 1
                                  }. `}{" "}
                                  {` ${que.title}.`}
                                </Text>
                              );
                            })}
                          </View>
                        </>
                      )}
                    </View>
                  );
                })}
              </View>
            </View>
          )}
          {/* 2nd page */}

          {/* 3rd Page: Skills */}

          <View break>
            <View>
              {showConentHeading(
                "SKILLS ASSOCIATED WITH THE TASKS YOU HAVE CARRIED OUT AS A VOLUNTEER"
              )}
            </View>
            {isSkillGraphVisible ? (
              <>
                <View style={{ marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    While carrying out these tasks, it is likely that you
                    demonstrated the following skills. Your Top skills (those
                    you are likely to have demonstrated most often) are:
                  </Text>

                  {/* -- */}
                  <View style={[styles.grayBlockStyle]}>
                    <View>
                      {formattedPillar.map(
                        (obj, index) =>
                          // obj.occurrence >= 2 &&
                          index < 10 && (
                            <View style={{ lineHeight: "1.2" }} key={index}>
                              <View
                                style={[
                                  styles.skillTitle,
                                  // index < 5 && styles.heighlightStyle,
                                ]}
                                key={index}
                              >
                                <Text
                                  style={{ width: "10px", marginRight: "5px" }}
                                >{`${index < 9 ? "0" : ""}${index + 1} `}</Text>
                                <Text>{` ${obj.skillTitle}`}</Text>
                              </View>
                            </View>
                          )
                      )}
                    </View>
                  </View>
                  {/* -- */}
                </View>

                <View style={{ marginHorizontal: 10, marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    Please see the section at the end entitled Detailed
                    Explanation of Results which shows all of the Skills you may
                    have demonstrated less frequently.
                  </Text>
                </View>
              </>
            ) : (
              <View style={[styles.grayBlockStyle]}>
                <Text>
                  Your answers are not sufficient to generate a score.
                </Text>
              </View>
            )}
          </View>

          {/* ------ skill Charts ----- */}
          {isSkillGraphVisible && (
            <View id="skill_barChart" break>
              <View>
                <View style={{ marginTop: 10 }}>
                  {showConentHeading(
                    "TOP SKILLS ASSOCIATED WITH THE TASKS YOU CARRIED OUT"
                  )}
                </View>
                <View style={{ marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    Graph showing how many times your top skills were identified
                    by the analysis. The skills with the highest scores are the
                    ones you have practised most and are likely to be your
                    strongest.
                  </Text>
                </View>
                {/* {skillHorizontalBarChart} */}
                <View style={[styles.grayBlockStyle, { marginTop: 20 }]}>
                  <Image
                    style={{ height: "auto", width: "100%" }}
                    src={skillBarChartUrl}
                  />
                </View>
              </View>
            </View>
          )}

          {/* ------ skill Charts ----- */}

          {/* 3rd Page: Skills */}

          {/* 4th Page: Attributes: */}

          <View break>
            <View>{showConentHeading("PERSONAL QUALITIES / ATTRIBUTES")}</View>
            {isAttributeGraphVisible ? (
              <>
                <View style={{ marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    From your responses, you are likely to have the following
                    personal qualities/attributes. Please note that the list
                    only shows personal qualities/attributes associated with the
                    questions where your average level of agreement is 3 or
                    more.
                  </Text>

                  <View style={{ marginTop: 5 }}>
                    <View style={styles.grayBlockStyle}>
                      {attribute.map(
                        (title, index) =>
                          index < 10 && (
                            <View style={[styles.skillTitle]} key={index}>
                              <View
                                style={[
                                  styles.skillTitle,
                                  // index < 5 && styles.heighlightStyle,
                                ]}
                                key={index}
                              >
                                <Text
                                  style={{ width: "10px", marginRight: "5px" }}
                                >{`${index < 9 ? "0" : ""}${index + 1} `}</Text>
                                <Text>{` ${title.skillTitle}`}</Text>
                              </View>
                            </View>
                          )
                      )}
                    </View>
                  </View>
                </View>

                <View style={{ marginHorizontal: 10, marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    Please see the section at the end entitled Detailed
                    Explanation of Results which shows all of the attributes and
                    personal qualities you have shown when volunteering.
                  </Text>
                </View>
              </>
            ) : (
              <View style={[styles.grayBlockStyle]}>
                <Text>
                  Your answers are not sufficient to generate a score.
                </Text>
              </View>
            )}
          </View>

          {/* 4th Page: Attributes: */}

          {/* ------ attribute  Charts ----- */}
          {isAttributeGraphVisible && (
            <View id="attribute_barChart" break>
              <View>
                <View style={{ marginTop: 10 }}>
                  {showConentHeading(
                    "TOP PERSONAL QUALITIES FROM YOUR VOLUNTEERING"
                  )}
                </View>
                <View style={{ marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    Graph showing your Top personal qualities identified by the
                    analysis with their average scores.
                  </Text>
                </View>
                <View style={[styles.grayBlockStyle, { marginTop: 20 }]}>
                  <Image
                    style={{ height: "auto", width: "100%" }}
                    src={attributebarChartUrl}
                  />
                </View>
              </View>
            </View>
          )}

          {/* ------ attribute  Charts ----- */}

          {(formattedPillar?.length || attributeFormatted?.length) && (
            <>
              <View break></View>
              {showPageHeader("DETAILED EXPLANATION OF RESULTS")}
            </>
          )}

          {/* 5th Page: Table: */}
          <View>
            <View id="skills_tableDescription">
              {formattedPillar?.length && (
                <View>
                  <View style={styles.pageContent}>
                    {showConentHeading("Skills Occurrences")}
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text style={styles.headingText3}>
                      Tabulated results for skills related to each activity with
                      number of occurrences for each skill:
                    </Text>
                  </View>

                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={[styles.tableColOne, styles.tableColHeader]}>
                        <Text style={styles.tableCellHead}>Skill</Text>
                      </View>
                      <View style={[styles.tableCol, styles.tableColHeader]}>
                        <Text style={styles.tableCellHead}>Occurrences</Text>
                      </View>
                      <View
                        id="related_task_col"
                        style={[styles.tableColThird, styles.tableColHeader]}
                      >
                        <Text style={styles.tableCellHead}>Related Tasks</Text>
                      </View>
                      <View style={[styles.tableCol, styles.tableColHeader]}>
                        <Text style={styles.tableCellHead}>Rating</Text>
                      </View>
                    </View>

                    {formattedPillar.length > 0 &&
                      formattedPillar.map((skillTitle, index) => {
                        return (
                          <>
                            {skillTitle.relatedPillarTitle.map(
                              (pillarTitle, pillarIndex) => {
                                return (
                                  <View style={styles.tableRow} key={index}>
                                    <View style={styles.tableColOne}>
                                      <Text style={styles.tableCell}>
                                        {pillarIndex === 0 &&
                                          skillTitle.skillTitle}
                                      </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>
                                        {pillarIndex === 0 &&
                                          skillTitle.occurrence}
                                      </Text>
                                    </View>
                                    <View
                                      id="related_task_data"
                                      style={styles.tableColThird}
                                    >
                                      <Text style={styles.tableCell}>
                                        Activity {pillarTitle.pillarSequence} -
                                        T
                                        {pillarTitle.taskSequence
                                          .split(".")
                                          .pop()}
                                        {` ${pillarTitle.taskTitle}`}
                                      </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>
                                        {skillTitle.value[pillarIndex]}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }
                            )}
                          </>
                        );
                      })}
                  </View>
                </View>
              )}
            </View>
          </View>
          {/* 5th Page: Table: */}

          {isAttributeGraphVisible && (
            <View id="attribute_tableDescription" break={isSkillGraphVisible}>
              <View>
                <View
                  style={[
                    isSkillGraphVisible
                      ? { marginTop: 10 }
                      : styles.pageContent,
                  ]}
                >
                  {showConentHeading("ATTRIBUTE OCCURRENCES")}
                </View>
                <View style={{ marginTop: 10 }}>
                  <Text style={styles.headingText3}>
                    Tabulated results for attribute related to personal
                    qualities/attribute with average score for each attribute:
                  </Text>
                </View>

                <View style={styles.tableAttr}>
                  <View style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableColOne,
                        styles.tableColHeader,
                        { width: "80%" },
                      ]}
                    >
                      <Text style={styles.tableCellHead}>Attribute</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        styles.tableColHeader,
                        { width: "20%" },
                      ]}
                    >
                      <Text style={styles.tableCellHead}>Average Rating</Text>
                    </View>
                  </View>

                  {attributeFormatted.length > 0 &&
                    attributeFormatted.map((skillTitle, index) => {
                      return (
                        <View style={styles.tableRow} key={index}>
                          <View style={[styles.tableColOne, { width: "80%" }]}>
                            <Text style={styles.tableCell}>
                              {skillTitle.skillTitle}
                            </Text>
                          </View>
                          <View style={[styles.tableCol, { width: "20%" }]}>
                            <Text style={styles.tableCell}>
                              {skillTitle.ratingAvg}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
          )}
          <View
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "space-around",
              textAlign: "center",
              alignContent: "center",
            }}
            break
          >
            <View style={{ alignContent: "center", textAlign: "center" }}>
              <Image
                style={{ width: "100%", height: "auto" }}
                src={APP_PDF_FOOTER_LOGO}
              />
            </View>
            {/* <Image
              style={{ width: "100%", height: "auto" }}
              src={APP_PDF_FOOTER_IMAGE}
            /> */}
          </View>

          {/* ------------ PAGE FOOTER START------------  */}
          <View style={styles.pageFooterImg} fixed>
            <Image
              style={{ width: "100%", height: "90%" }}
              src={PAGE_FOOTER_IMAGE}
            />
          </View>
          <Text
            style={styles.pageNumberStyle}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          {/* ------------ PAGE FOOTER END ------------  */}
        </Page>
      </Document>
    </>
  );
};

export default Pdf;
