import React, { useState } from "react";
import { PILLAR_TEXT_COLORS, PILLAR_BG_COLORS } from "../../constants/index";
import { getPillarBgColorByIndex } from "../utility";

const STICK_VALUES = [0, 1, 2, 3, 4];
const STICK_TITLE = [
  { title: "No answer", style: "col-span-2" },
  { title: "1", style: "col-span-2 pl-7" },
  { title: "2", style: "col-span-2 pl-[3rem]" },
  { title: "3", style: "col-span-2 pl-[4.50rem]" },
  { title: "4", style: "col-span-2 pl-[6rem]" },
];

const Slider = ({
  handleChange,
  selectedValue = 0,
  isDisable,
  style = {},
  activeStepIndex,
  selectedStepData,
  isMozilla,
}) => {
  //const [check, setCheck] = useState(selectedValue);
  //console.log("selectedStepData", selectedStepData);

  const calculateThumbColor = () => {
    let colorBg;
    //console.log("selectedStepData.sequence", selectedStepData.sequence);

    if (selectedStepData.sequence === "1") {
      return (colorBg = "#b1d100");
    } else if (selectedStepData.sequence === "2") {
      return (colorBg = "#f75e00");
    } else if (selectedStepData.sequence === "3") {
      return (colorBg = "#6519ff");
    } else if (selectedStepData.sequence === "4") {
      return (colorBg = "#991b1b");
    } else if (selectedStepData.sequence === "5") {
      return (colorBg = "#76527f");
    } else if (selectedStepData.sequence === "6") {
      return (colorBg = "#831843");
    } else if (selectedStepData.sequence === "7") {
      return (colorBg = "#50939d");
    } else if (selectedStepData.sequence === "8") {
      return (colorBg = "#009fdc");
    }
  };

  const thumbStyles = {
    background: calculateThumbColor(), // Change the background color to red
    width: "20px", // Adjust the width of the thumb as needed
    height: "20px", // Adjust the height of the thumb as needed
    borderRadius: "50%", // Make the thumb round
  };

  const calculateColor = () => {
    const percentage = (selectedValue / 4) * 100;
    if (selectedStepData.sequence === "1") {
      return `linear-gradient(to right, #b1d100 ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "2") {
      return `linear-gradient(to right, #f75e00 ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "3") {
      return `linear-gradient(to right, #6519ff ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "4") {
      return `linear-gradient(to right, #991b1b ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "5") {
      return `linear-gradient(to right, #76527f ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "6") {
      return `linear-gradient(to right, #831843 ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "7") {
      return `linear-gradient(to right, #50939d ${percentage}%, #D3D3D3 ${percentage}%)`;
    } else if (selectedStepData.sequence === "8") {
      return `linear-gradient(to right, #009fdc ${percentage}%, #D3D3D3 ${percentage}%)`;
    }
  };

  
  return (
    <div className={`flex flex-col items-center ${style}`}>
      <div>
        <input
          type="range"
          min="0"
          max={STICK_VALUES.length - 1}
          value={selectedValue}
          /*  className={`range range-xs ${
            (activeStepIndex === 0 && "range-primary") ||
            (activeStepIndex === 1 && "range-secondary") ||
            (activeStepIndex === 2 && "range-accent") ||
            (activeStepIndex === 3 && "range-info")
          } ${isDisable && "cursor-not-allowed"}`} */
          className={`relative z-30 w-full flex h-2 appearance-none rounded [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[17px] [&::-webkit-slider-thumb]:w-[17px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-purple-500`}
          style={{ background: calculateColor() }}
          step=""
          onChange={handleChange}
          /*  disabled={isDisable} */
        />
        <style>
          {`
            ${
              isMozilla === true
                ? `input[type="range"]::-moz-range-thumb {
              -webkit-appearance: none;
              appearance: none;
              ${Object.entries(thumbStyles)
                .map(([property, value]) => `${property}: ${value};`)
                .join("\n")}
            }`
                : `input[type="range"]::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              ${Object.entries(thumbStyles)
                .map(([property, value]) => `${property}: ${value};`)
                .join("\n")}
            }`
            }
          `}
        </style>
        <div className="relative z-10 grid grid-cols-10 gap-x-[6.85rem]">
          {STICK_VALUES.map((val) => {
            return (
              <div className={`flex ${STICK_TITLE[val].style} -mt-3`}>
                <div
                  className={`rounded-full h-4 w-4 ${
                    parseInt(selectedValue, 10) >= val
                      ? getPillarBgColorByIndex(selectedStepData.sequence - 1)
                      : // ? "bg-green-700"
                        "bg-[#D3D3D3]"
                  } `}
                ></div>
              </div>
            );
          })}
        </div>
        <div class="relative text-sm text-slate-700 grid grid-cols-10 gap-x-[6.85rem] my-2.5">
          {STICK_VALUES.map((val) => {
            return (
              <div
                className={`flex font-bold ${STICK_TITLE[val].style}`}
                style={{
                  color:
                    parseInt(selectedValue, 10) === val &&
                    calculateThumbColor(),
                }}
              >
                {STICK_TITLE[val].title}
              </div>
            );
          })}
        </div>
      </div>

      <div className="divider"></div>
    </div>
  );
};

export default Slider;
