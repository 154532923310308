import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ERROR_CAPTCHA_CHECK, ERROR_PRIVACY_POLICY } from "../../constants";
import { captch_token_api, login_api } from "../../constants/api";

import { loginFields } from "../../constants/formFields";
import { setUserAuth } from "../../service/Auth";
import Spinner from "../common/Spinner";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import { PolicyCheck } from "./PolicyCheck";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { verifyReCaptchaToken } from "../../service";

const fields = loginFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Login() {
  const [loginState, setLoginState] = useState(fieldsState);
  const [errorMsg, setError] = useState(null);
  const [isloading, setLoading] = useState(false);
  const [isPolicyAccepted, setPolicyAccepted] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const recaptchaRef_login = useRef();

  const redirectpath = location.state?.path || "/";

  // const navigateTo = (path) => navigate(path, { replace: true });

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  // Toggle privacy-policy checkbox
  const handleOnPloicyCheckChange = (value) => {
    setPolicyAccepted(!isPolicyAccepted);
    if (!isPolicyAccepted) {
      setError("");
    }
  };

  const verifyCaptchaToken = async (token) => {
    try {
      // TODO: Add backend api to verify captch token.
      return token ? true : false;
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPolicyAccepted) {
      // validate captcha token
      const token = recaptchaRef_login.current.getValue();

      if (token) {
        let valid_token = await verifyCaptchaToken(token);
        if (valid_token) {
          authenticateUser();
        }
      } else {
        setError(ERROR_CAPTCHA_CHECK);
        recaptchaRef_login.current.reset();
      }
    } else {
      setError(ERROR_PRIVACY_POLICY);
    }
  };

  //Handle Login API Integration here
  const authenticateUser = () => {
    setLoading(true);
    fetch(login_api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginState),
    })
      .then((response) => response.json())
      .then((data) => {
        //API Success from LoginRadius Login API
        if (data.code && data.message) {
          setError(data.message);
          setLoading(false);
        } else {
          //API Success from LoginRadius Login API
          setUserAuth(data);
          setLoading(false);
          navigate(redirectpath, { replace: true });
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  const showErrorMsg = () => {
    if (!errorMsg) return;

    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{errorMsg}</span>
        <span
          className="absolute top-0 bottom-0 right-0 px-4 py-3"
          onClick={() => setError(null)}
        >
          <svg
            className="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    );
  };

  const showCaptchaBlock = () => {
    if (!process.env.REACT_APP_reCAPTCHA_SITE_KEY) {
      alert(
        "Opps! Something went wrong.Please contact you admin or try again later. \nError: Missing sitekey"
      );
      return;
    }

    return (
      <div id="reCaptchBlock">
        <ReCAPTCHA
          ref={recaptchaRef_login}
          sitekey={process.env.REACT_APP_reCAPTCHA_SITE_KEY}
        />
      </div>
    );
  };

  return (
    <form className="mt-2 space-y-6" onSubmit={handleSubmit}>
      <div className="" tabIndex={0}>
        {isloading && (
          <div className="flex h-screen absolute -top-10 left-1/2 z-50">
            <Spinner />
          </div>
        )}
        {fields.map((field) => (
          <Input
            key={field.id}
            handleChange={handleChange}
            value={loginState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
      </div>
      <FormExtra />
      {showCaptchaBlock()}
      <PolicyCheck
        btnText="By logging in, I accept the"
        isChecked={isPolicyAccepted}
        handleOnPloicyCheckChange={handleOnPloicyCheckChange}
      />
      {showErrorMsg()}
      <FormAction
        handleSubmit={handleSubmit}
        text="Login"
        isDisable={isloading}
      />
    </form>
  );
}
