import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import moment from "moment";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import {
  EDIT_ACTION,
  PILLAR_TITLE_PREFIX,
  SUBMIT_STATUS,
  UPDATE_STATUS,
  VIEW_ACTION,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { themeDefault } from "../../constants/themeSettings";
import Pdf from "../Pdf/Pdf";
import PdfResultInfo from "../Pdf/PdfResultInfo";
import { createNewNumber } from "../utility/index";
import { saveAs } from "file-saver";
import IconDownload from "../common/IconDownload";
import SampleBarChart from "../Charts/SampleBarChart";
import * as htmlToImage from "html-to-image";

const btn_bg_color_hover = `${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`;

// This is min-threshould value for rating to filter data
const RATING_THRESHOULD_VAL = 3;

const Card = (props) => {
  const {
    data,
    actionType,
    onRowClick,
    onSearchChange,
    searchText,
    tableFilter,
    sortColumn,
    sortOrder,
    handleSetSortOrder,
    handleGraphClick,
    handleDeleteFeedbackClick,
  } = props;

  const [expandableData, setExpandableData] = useState(data);
  const [preparingDataLoader, setPreparingDataLoader] = useState(null);

  const onExpandResult = (index, feedBackIndex) => {
    const result = expandableData.slice(0);
    result[index].feedback[feedBackIndex]["expandable"] = result[index]
      .feedback[feedBackIndex]["expandable"]
      ? false
      : true;
    //console.log("RESULT", result)
    setExpandableData(result);
  };

  /*  const expandResultOnLoad = (data) => {
    
      data.forEach((item) => {
        item.feedback.forEach((feedbackItem) => {
          feedbackItem.expandable = true;
        });
      });
    
  }; */

  useEffect(() => {
    if (data) {
      // const expand = [...data];
      let expand = JSON.parse(JSON.stringify(data));
      const response = expand.map((row) => {
        const feedback = (row.feedback || []).map((item) => {
          const index = (item.question || []).filter((queValue) => {
            return queValue?.value > "0";
          });
          if (index.length > 0) {
            item.question = index;
            return item;
          }
        });

        row.feedback = feedback.filter((row) => !!row);
        return row;
      });
      //expandResultOnLoad(response)
      setExpandableData(response);
    }
  }, [data]);

  let navigate = useNavigate();

  const filterQuestion = (feedbackArray) => {
    return feedbackArray.map((feedback) => {
      let filteredQuestions = feedback.question;
      if (feedback.___class !== "AttributeTheme") {
        filteredQuestions = feedback.question.filter(
          (question) => question.value === "3" || question.value === "4"
        );
      }
      return { ...feedback, question: filteredQuestions };
    });
  };

  const extractPillarFromData = (arrayOfObjects) => {
    const allQuestion = [];

    for (const obj of arrayOfObjects) {
      if (obj.question && Array.isArray(obj.question)) {
        allQuestion.push(...obj.question);
      }
    }
    return allQuestion;
  };

  function generateSkillData(data) {
    const skillOccurrences = new Map();

    // Iterate through the data to count skill occurrences and related titles/tasks
    data.forEach((pillar) => {
      pillar.question.forEach((task) => {
        task.skills.forEach((skill) => {
          const skillTitle = skill.title;
          let sum = task.value;

          if (!skillOccurrences.has(skillTitle)) {
            skillOccurrences.set(skillTitle, {
              skillTitle: skillTitle,
              occurrence: 0,
              relatedPillarTitle: [],
              value: [],
              ratingAvg: 0,
            });
          }

          const skillData = skillOccurrences.get(skillTitle);
          skillData.occurrence++;

          skillData.relatedPillarTitle.push({
            pillarSequence: pillar.sequence,
            taskSequence: task.sequence,
            taskTitle: task.title,
          });
          skillData.value.push(task.value);

          // Calculate rating value avg
          sum = parseInt(sum) + parseInt(skillData.ratingAvg);
          skillData.ratingAvg = sum / skillData.occurrence;
        });
      });
    });

    let main = Array.from(skillOccurrences.values());
    return main;
  }

  function sortDataDescendingByRatingAvg(data) {
    const filterByThreshould = data.filter(
      (obj) => obj.ratingAvg >= RATING_THRESHOULD_VAL
    );
    return filterByThreshould.sort((a, b) => b.ratingAvg - a.ratingAvg);
  }

  function sortDataDescendingByOccurrence(data) {
    return data.sort((a, b) => b.occurrence - a.occurrence);
  }

  /*    const createSubarrays = (inputArray) => {
     const length = inputArray.length;
     const middleIndex = Math.floor(length / 2);

     const subarray1 = inputArray.slice(0, middleIndex);
     const subarray2 = inputArray.slice(middleIndex);

     return [subarray1, subarray2];
   }; */

  const createSubarrays = (arr) => {
    const subarrayOne = [];
    const subarrayTwo = [];

    for (let i = 0; i < arr.length; i++) {
      if (i % 2 === 0) {
        subarrayOne.push(arr[i]);
      } else {
        subarrayTwo.push(arr[i]);
      }
    }

    return [subarrayOne, subarrayTwo];
  };

  const onBuildUpMyProfile = (row) => {
    // navigate(`/feedback/${row.objectId}`)
    const getOrignalRow = data.find((e) => e.objectId === row.objectId);

    if (getOrignalRow && getOrignalRow.objectId) {
      navigate(`/pillar/edit/${getOrignalRow.objectId}`, {
        state: { feedbackObject: getOrignalRow },
      });
    }
  };

  const generatePDFDocument = async (row) => {
    let objCopy = { ...row };
    setPreparingDataLoader(objCopy.objectId);

    let filteredQuestionArr = filterQuestion(objCopy.feedback);
    objCopy.feedback = filteredQuestionArr;
    let slicedArr = objCopy.feedback.slice(0, -1);

    let formattedDataPillar = generateSkillData(slicedArr);
    let sortedFormatedDataPillar =
      sortDataDescendingByOccurrence(formattedDataPillar);
    let halfFormattedPillar = createSubarrays(sortedFormatedDataPillar);

    let allQuestion = extractPillarFromData(slicedArr);
    const attributeLast = objCopy.feedback.slice(-1);
    let formattedAttribute = generateSkillData(attributeLast);
    let sortedAttributePillar =
      sortDataDescendingByRatingAvg(formattedAttribute);

    const responedRolesList = objCopy.feedback.filter(
      (obj) => obj.___class != "AttributeTheme"
    );

    const dateField = objCopy.updated ? objCopy.updated : objCopy.created;
    const datetext = moment(new Date(dateField)).format("DD-MM-YYYY");
    const timeText = moment(new Date(dateField)).format("HH:mm a");
    const fileName = `SELF-REFLECTION ${datetext} at ${timeText}`;

    const skillBarChartUrl = await htmlToImage.toPng(
      document.getElementById("barchartSkill")
    );

    const attributebarChartUrl = await htmlToImage.toPng(
      document.getElementById("barchartAttribute")
    );

    const blob = await pdf(
      <Pdf
        data={objCopy}
        responedRolesList={responedRolesList}
        sortedFormatedDataPillar={sortedFormatedDataPillar}
        allQuestion={allQuestion}
        sortedAttributePillar={sortedAttributePillar}
        DataOne={halfFormattedPillar[0]}
        DataTwo={halfFormattedPillar[1]}
        skillBarChartUrl={skillBarChartUrl}
        attributebarChartUrl={attributebarChartUrl}
      />
    ).toBlob();
    setPreparingDataLoader(null);
    saveAs(blob, fileName);
  };

  const noDataText = () => {
    return <span className="text-slate-400">No data</span>;
  };

  const showStatusCell = (row) => {
    const status = row.status;
    if (!status) return noDataText();

    const statyStyle =
      status === UPDATE_STATUS
        ? "bg-red-200 text-red-500"
        : "bg-green-200 text-green-600";

    return (
      <>
        <p
          className={`h-[7rem] w-[7rem] pt-11 rounded-full font-semibold text-slate-600 ${statyStyle}`}
        >
          {status === "Pending" ? "In Progress" : "Completed"}
        </p>
      </>
    );
  };

  const getFormEditActionIconNew = (row) => {
    const disableClass = "opacity-30 disabled:cursor-not-allowed";
    const isDisable = false; // Add condition on for which button will be disabled
    const canEdit = row.status === UPDATE_STATUS; // Disable edit for submitted forms.

    return (
      <button
        className={`flex items-center justify-center text-lg h-16 w-auto bg-transparent hover:bg-sky-700 ${themeDefault.text_color_dark} font-semibold hover:text-white py-2 px-4 border border-sky-500 hover:border-transparent rounded-md`}
        onClick={() => navigate(`/feedback/${row.objectId}`)}
      >
        {canEdit ? "Continue the self-reflection" : "View my answers"}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          style={{ marginLeft: "20px" }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    );
  };

  const showSpiner = () => {
    return <span className="loading loading-dots loading-xs text-white"></span>;
  };

  const getFormEditActionIcon = (row) => {
    if (actionType.includes("view")) {
      const disableClass = "opacity-30 disabled:cursor-not-allowed";
      const isDisable = false; // Add condition on for which button will be disabled
      const canEdit = row.status === UPDATE_STATUS; // Disable edit for submitted forms.

      const btnClass =
        "h-8 px-2 py-2 ml-2 leading-none rounded text-white font-semibold text-xs";

      return (
        <div className="flex justify-end py-2">
          {canEdit ? (
            <button
              type="button"
              onClick={() => navigate(`/feedback/${row.objectId}`)}
              className={`${btnClass} leading-none rounded text-white font-semibold text-xs  ${btn_bg_color_hover} ${
                isDisable && disableClass
              }`}
              disabled={isDisable}
            >
              Continue the self-reflection
            </button>
          ) : (
            <button
              type="button"
              onClick={() => onBuildUpMyProfile(row)}
              className={`${btnClass} leading-none rounded text-white font-semibold text-xs  ${btn_bg_color_hover} ${
                isDisable && disableClass
              }`}
              disabled={isDisable}
            >
              Build up my profile
            </button>
          )}

          {
            <button
              onClick={() => generatePDFDocument(row)}
              className={`${btnClass}  ${btn_bg_color_hover} ${
                row.status !== SUBMIT_STATUS && disableClass
              } tooltip`}
              disabled={row.status !== SUBMIT_STATUS}
              data-tip="Please check the download folder once you click on this button"
            >
              <div className="flex justify-center items-center">
                {row.objectId === preparingDataLoader ? (
                  <>
                    {showSpiner()}
                    <span className="pl-1">{"Preparing Report"}</span>
                  </>
                ) : (
                  <>
                    <IconDownload size={4} color="text-white" />
                    <span className="pl-1">
                      {"Full Report your sport volunteering profile"}
                    </span>
                  </>
                )}
                {/* <span className="pl-1">{preparingDataLoader ? 'Preparing Pdf': 'Download Pdf'}</span> */}
              </div>
            </button>
          }
          {
            // Delete feedback button
            <button
              type="button"
              onClick={() => handleDeleteFeedbackClick(row)}
              className={`${btnClass} bg-red-600 hover:bg-red-700`}
              style={{ left: "120px" }}
            >
              Delete my session
            </button>
          }
        </div>
      );
    }
  };

  const downArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 ml-2 text-slate-400 font-bold"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    );
  };

  const upArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 ml-2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.5 15.75l7.5-7.5 7.5 7.5"
        />
      </svg>
    );
  };

  const displayDate = (value) => {
    const dateField = value.updated ? value.updated : value.created;

    const prefixText = value.updated ? "Updated on " : "Created on ";
    const datetext = moment(new Date(dateField)).format("DD/MM/YYYY");
    const timeText = moment(new Date(dateField)).format("HH:mm a");

    return (
      <div className="text-base text-slate-500 font-medium">
        <span className="absolute top-2 right-0 pb-3 pt-4 pl-2 pr-10">
          {prefixText}
          {datetext}
        </span>
        <span className="absolute top-8 right-0 pb-3 pt-4 pl-2 pr-10">
          {`at ${timeText}`}
        </span>
      </div>
    );
  };

  const isAttribute = (value) => {
    if (value.___class === "AttributeTheme") return true;
    return false;
  };

  const barChartsBlock = (row) => {
    let objCopy = { ...row };
    let filteredQuestionArr = filterQuestion(objCopy.feedback);
    objCopy.feedback = filteredQuestionArr;
    let slicedArr = objCopy.feedback.slice(0, -1);

    let formattedDataPillar = generateSkillData(slicedArr);
    let sortedFormatedDataPillar =
      sortDataDescendingByOccurrence(formattedDataPillar);

    const attributeLast = objCopy.feedback.slice(-1);
    let formattedAttribute = generateSkillData(attributeLast);
    let sortedAttributePillar =
      sortDataDescendingByRatingAvg(formattedAttribute);

    // SHOW TOP 10
    const NUMBER_OF_BARS = 10;
    return (
      <div>
        <div id="barchartSkill">
          <SampleBarChart
            id="barchartSkill"
            data={sortedFormatedDataPillar.slice(0, NUMBER_OF_BARS)}
            key1={"occurrence"}
            legendText={"Number of occurrences"}
            key1Title={"skillTitle"}
          />
        </div>

        <div id="barchartAttribute">
          <SampleBarChart
            id="barchartSkill"
            data={sortedAttributePillar.slice(0, NUMBER_OF_BARS)}
            key1={"ratingAvg"}
            legendText={"Average score"}
            key1Title={"ratingAvg"}
          />
        </div>
      </div>
    );
  };

  return (
    <div class="p-5 bg-gray-50">
      <h1
        class={`text-xl font-medium bg-gray-50 ${themeDefault.text_color_dark} py-2 mb-4 sticky top-16 right-0 z-20`}
      >
        SELF-REFLECTION RESULTS
      </h1>
      <h2 className="pb-8 font-semibold text-slate-500">
        Click on the relevant button to continue the self-reflection, to build
        up the profile, to delete your session, or to generate a full report of
        your sport volunteering profile. The report will appear in your Download
        folder.
      </h2>
      {!expandableData ||
        (!expandableData.length && (
          <div
            className="card bg-base-100 shadow-xl mb-5 flex justify-center items-center text-lg font-semibold text-slate-500"
            style={{ minHeight: "150px" }}
          >
            No self-reflection started yet. Please click 'Start the
            self-reflection' to start!
          </div>
        ))}
      {expandableData &&
        expandableData.map((singleValue, index) => {
          return (
            <div
              className="card bg-base-100 shadow-xl mb-5 flex flex-row transition ease-in-out hover:-translate-y-1 hover:scale-5 hover:bg-sky-50"
              style={{ minHeight: "150px" }}
            >
              <div
                className="relative left-7 p-3 mr-4 text-base text-slate-700"
                style={{ margin: "auto" }}
              >
                {showStatusCell(singleValue)}
              </div>
              {displayDate(singleValue)}
              <div className="z-10 card-body flex text-left pb-2 mt-2">
                {(singleValue.feedback || []).map(
                  (feedbackValue, feedBackIndex) => {
                    return (
                      <div>
                        <div
                          className="ml-2 font-bold text-base text-slate-600 hover:opacity-70 flex items-center"
                          onClick={() => onExpandResult(index, feedBackIndex)}
                          style={{ cursor: "pointer" }}
                        >
                          {`${
                            isAttribute(feedbackValue)
                              ? ""
                              : `${PILLAR_TITLE_PREFIX} ${feedbackValue?.sequence}:`
                          }  ${feedbackValue?.title}`}
                          {feedbackValue?.expandable === true
                            ? upArrow()
                            : downArrow()}
                        </div>
                        <div className="mt-1">
                          {feedbackValue?.expandable === true
                            ? feedbackValue.question.map(
                                (subThemeTitle, subThemeIndex) => {
                                  return (
                                    <div className="pl-8 text-sm font-medium text-slate-500 leading-loose">
                                      {`${
                                        isAttribute(feedbackValue) ? "A" : "T"
                                      }${createNewNumber(
                                        subThemeTitle.sequence
                                      )}: ${subThemeTitle.title}`}
                                    </div>
                                  );
                                }
                              )
                            : null}
                        </div>
                      </div>
                    );
                  }
                )}
                {(!singleValue.feedback ||
                  singleValue.feedback.length === 0) && (
                  <span className="card-body font-bold text-lg text-slate-400">
                    Not answered !
                  </span>
                )}
                {getFormEditActionIcon(singleValue)}
              </div>

              <div style={{ opacity: 0, position: "absolute", zIndex: 0 }}>
                <div className="flex-row">{barChartsBlock(singleValue)}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Card;
