import React, { useState, useEffect } from "react";
import {
  getTheme,
  updateQuestionApi,
  updateAttributeApi,
  updateSubThemeApi,
  addNewQuestion,
  addNewAttributeQuestion,
  addRelation,
  addAttributeRelation,
  getAttribute,
} from "../../service";
import { LoadingSkeleton } from "../common/LoadingSkeleton";

import ErrorPage from "../common/ErrorPage";

import { useNavigate, useLocation } from "react-router-dom";
import { getUser, clearUser } from "../../service/Auth";
import NewFeedbackForm from "./NewFeedbackForm";
import {
  ALERT_TYPE_ERROR,
  ALERT_TYPE_SUCCESS,
  TABLE_QUESTION,
  TABLE_ATTRIBUTE_QUESTION,
  TABLE_SUB_THEME,
} from "../../constants";
import ToastMessage from "../common/ToastMessage";
import Spinner from "../common/Spinner";
import { sortDataMain } from "../../components/utility";
import AppFooter from "../common/AppFooter";

const NewWizard = (props) => {
  const { selectedFormData } = props;
  const user = getUser();
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);
  const location = useLocation();
  const data = location?.state;

  const [isLoading, setLoader] = useState(false);
  const [isError, setError] = useState("");
  const [themeData, setThemeData] = useState([]);

  const [isProcessing, setisProcessing] = useState(false);
  const [alert, setAlert] = useState(null);

  const [formData, setFormData] = useState(selectedFormData || {});

  const [activeStepIndex, setselectedStepIndex] = useState(0); //This is object of selected step
  const [activeStepData, setselectedStepData] = useState({}); //This is object of selected step

  const [newQuestionText, setNewQuestionText] = useState("");
  const [showNewQuestionBlockForId, setshowNewQuestionBlockForId] =
    useState(null);

  const [isEditModeEnable, setisEditModeEnable] = useState(false);
  const [showAllTitles, setShowAllTitles] = useState(false);
  const [moveableData, setmovableData] = useState([]);
  const [attribute, setAttribute] = useState([]);

  //console.log("formData", formData, activeStepData, activeStepIndex);
  //console.log("dataChecking", data, selectedFormData, themeData);

  //console.log("themeData", themeData, attribute)

  useEffect(() => {
    setLoader(true);
    //console.log("hello")

    if (data !== null) {
      setselectedStepData(data[activeStepIndex]);
      setThemeData(data);
      //setmovableData(data.slice(0, 4));
      setLoader(false);
      setisProcessing(false);
    } else {
      setThemeData(selectedFormData.feedback);
      setselectedStepData(selectedFormData.feedback[activeStepIndex]);
      //setmovableData(selectedFormData?.feedback?.slice(0, 4));
      setLoader(false);
      setisProcessing(false);
    }
    //console.log("useEffect", data);
  }, []);

  const fetchFormInitialData = async () => {
    try {
      let themeData = await getTheme();
      let attributeData = await getAttribute();

      let combinedData = [...themeData, ...attributeData];
      let sortedData = sortDataMain(combinedData);

      setThemeData(sortedData);
      setselectedStepData(sortedData[activeStepIndex]);
      setLoader(false);
      setisProcessing(false);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setLoader(false);
      setisProcessing(false);
      setError(
        "Error occured while fetching Theme api data ! Please contact to admin."
      );
    }
  };

  const handleLeftClick = () => {
    if (data !== null) {
      setShowAllTitles(false);
      setmovableData(data.slice(0, 4));
      setselectedStepIndex(0);
      setselectedStepData(data[0]);
    } else {
      setShowAllTitles(false);
      setmovableData(selectedFormData.feedback.slice(0, 4));
      setselectedStepIndex(0);
      setselectedStepData(selectedFormData.feedback[0]);
    }
  };
  const handleRightClick = () => {
    if (data !== null) {
      setmovableData(data.slice(4));
      //setselectedStepData(data[4]);
      //setselectedStepIndex(activeStepIndex)
      setShowAllTitles(true);
      setselectedStepIndex(4);
      setselectedStepData(data[4]);
      console.log("right clicked");
    } else {
      setmovableData(selectedFormData.feedback.slice(4));
      //setselectedStepData(data[4]);
      //setselectedStepIndex(activeStepIndex)
      setShowAllTitles(true);
      setselectedStepIndex(4);
      setselectedStepData(selectedFormData.feedback[4]);
      console.log("right clicked");
    }
  };

  const handleAlert = (alertValue) => {
    setisProcessing(false);
    setAlert(alertValue);
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const handleCloseToast = () => {
    setAlert(null);
  };

  const handleOnEnterSave = (value, objectId, tableName, columnName) => {
    console.log("tablename", tableName);
    // Based on table call update api
    setisProcessing(true);
    if (tableName === TABLE_SUB_THEME) {
      updateSubThemeApi(objectId, value, columnName)
        .then((response) => {
          if (!response.errorData) {
            console.log("updateSubThemeApi sucess");
            setisProcessing(false);
            handleAlert({
              message: "Data Updated successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          } else throw new Error("Something went wrong");
        })
        .catch(() => {
          setisProcessing(false);
          handleAlert({
            message: "Failed to update data! Please contact to admin.",
            type: ALERT_TYPE_ERROR,
          });
        });
    } else if (tableName === TABLE_QUESTION) {
      console.log("elseif subtheme");
      updateQuestionApi(objectId, value, columnName)
        .then((response) => {
          if (!response.errorData) {
            console.log("updateQuestionApi sucess");
            setisProcessing(false);
            handleAlert({
              message: "Data Updated successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          } else throw new Error("Something went wrong");
        })
        .catch(() => {
          setisProcessing(false);
          handleAlert({
            message: "Failed to update data! Please contact to admin.",
            type: ALERT_TYPE_ERROR,
          });
        });
    } else if (tableName === TABLE_ATTRIBUTE_QUESTION) {
      console.log("elseif attribute");
      updateAttributeApi(objectId, value, columnName)
        .then((response) => {
          if (!response.errorData) {
            console.log("updateAttributeQuestionApi sucess");
            setisProcessing(false);
            handleAlert({
              message: "Data Updated successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          } else throw new Error("Something went wrong");
        })
        .catch(() => {
          setisProcessing(false);
          handleAlert({
            message: "Failed to update data! Please contact to admin.",
            type: ALERT_TYPE_ERROR,
          });
        });
    }
  };

  const handleOnTextChange = (
    objectId,
    tableName,
    columnName,
    value,
    activeStepIndex,
    queIndex
  ) => {
    console.log(
      "important",
      tableName,
      columnName,
      value,
      activeStepIndex,
      queIndex
    );
    const newselectedStepData = { ...activeStepData };

    if (tableName === TABLE_QUESTION) {
      newselectedStepData.question[queIndex][columnName] = value;
    }

    handleSelectedStepData(newselectedStepData);
  };

  const handleNewQuestionTextChange = (text) => {
    setNewQuestionText(text);
  };
  const handleDiscardNewQuestion = () => {
    setshowNewQuestionBlockForId(null);
    setNewQuestionText("");
  };
  // Enables new question input block
  const handleShowNewQuestionClick = (id) => {
    setshowNewQuestionBlockForId(id);
  };

  const handleOnEditModeClick = () => {
    setisEditModeEnable(!isEditModeEnable);
  };

  const createQuestionSequence = (subtheme) => {
    let questionArrLength = subtheme.question.length;
    let subthemeSequenceLastValue = subtheme.question[questionArrLength - 1];
    let lastValueofQuestion = subthemeSequenceLastValue.sequence.split(".");
    let lastValueIncrement = parseInt(lastValueofQuestion[1]) + 1;

    let questionSequience = `${lastValueofQuestion[0]}.${lastValueIncrement}`;
    //console.log("questionSequience", questionSequience);
    return questionSequience;
  };

  const handleNewQuestionSave = (text, subTheme) => {
    try {
      console.log("subTheme", subTheme);
      let questionSequence = createQuestionSequence(subTheme);
      console.log("questionSequence", questionSequence);
      setisProcessing(true);
      if (!text) {
        setisProcessing(false);
        handleAlert({
          message: "Please Add some Text to new question.",
          type: ALERT_TYPE_ERROR,
        });
        return;
      }
      if (subTheme.sequence === "8") {
        addNewAttributeQuestion(text, questionSequence).then((data) => {
          console.log("attributeData", data);
          // Add relation to newly created question with related sub-theme
          addAttributeRelation(activeStepData.objectId, data.objectId).then(
            (data) => {
              //console.log("main", data)
              fetchFormInitialData();
              setNewQuestionText("");
              handleShowNewQuestionClick();
              handleAlert({
                message: "New question added successfully!",
                type: ALERT_TYPE_SUCCESS,
              });
            }
          );
        });
      } else {
        addNewQuestion(text, questionSequence).then((data) => {
          console.log("dataaddnew", data);
          // Add relation to newly created question with related sub-theme
          addRelation(activeStepData.objectId, data.objectId).then((data) => {
            //console.log("main", data)
            fetchFormInitialData();
            setNewQuestionText("");
            handleShowNewQuestionClick();
            handleAlert({
              message: "New question added successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          });
        });
      }
    } catch (error) {
      setisProcessing(false);
      handleAlert({
        message:
          "Error occured while adding Questions data ! Please contact to admin.",
        type: ALERT_TYPE_ERROR,
      });
    }
  };

  const handleSelectedStepData = (data) => {
    setselectedStepData(data);
  };

  // Handle step click : set index and data for index
  const handleOnStepClick = (stepIndex, stepData) => {
    if (themeData && stepIndex < themeData.length) {
      const stepDataObj = themeData && themeData[stepIndex];
      setselectedStepData(stepDataObj);
      setselectedStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    if (user === "null") {
      clearUser();
      navigateTo("/login");
      return;
    }
  }, [user]);

  if (isError) {
    return <ErrorPage />;
  }

  const singleFormView = () => {
    if (!themeData || !themeData.length) return null;

    return (
      <NewFeedbackForm
        data={themeData}
        defaultFormData={formData}
        activeStepIndex={activeStepIndex}
        selectedStepData={activeStepData}
        handleOnStepClick={handleOnStepClick}
        handleOnTextChange={handleOnTextChange}
        handleOnEnterSave={handleOnEnterSave}
        newQuestionText={newQuestionText}
        showNewQuestionBlockForId={showNewQuestionBlockForId}
        handleShowNewQuestionClick={handleShowNewQuestionClick}
        handleNewQuestionTextChange={handleNewQuestionTextChange}
        handleNewQuestionSave={handleNewQuestionSave}
        handleDiscardNewQuestion={handleDiscardNewQuestion}
        isEditModeEnable={isEditModeEnable}
        handleOnEditModeClick={handleOnEditModeClick}
        handleRightClick={handleRightClick}
        handleLeftClick={handleLeftClick}
        showAllTitles={showAllTitles}
        moveableData={moveableData}
        setselectedStepData={setselectedStepData}
        setselectedStepIndex={setselectedStepIndex}
        createQuestionSequence={createQuestionSequence}
      />
    );
  };

  return (
    <div>
      <div className="w-full py-10 px-4 sm:px-6 lg:px-8 ">
        <div className="mt-5 max-w-l flex justify-center rounded overflow-visible shadow-lg border">
          {isLoading ? <LoadingSkeleton /> : singleFormView()}

          {isProcessing && (
            <div className="flex h-screen fixed -top-10 left-1/2 z-50">
              <Spinner />
            </div>
          )}
          {alert && (
            <ToastMessage alert={alert} closeToast={handleCloseToast} />
          )}
        </div>
      </div>
      {!isLoading && <AppFooter />}
    </div>
  );
};

export default NewWizard;
