/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (
    item && typeof item === "object" && !Array.isArray(item) && item !== null
  );
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export function mergeDeep(target, source) {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
}

export const sortArrByDate = (
  dataArr = [],
  dateField = "date",
  sortBy = "desc"
) => {
  // return empty array if there is no data found
  if (!dataArr || !dataArr.length) {
    return [];
  }

  if (sortBy === "asc") {
    return dataArr.sort(
      (a, b) => new Date(a[dateField]) - new Date(b[dateField])
    );
  }
  // sort by date desc
  return dataArr.sort(
    (a, b) => new Date(b[dateField]) - new Date(a[dateField])
  );
};

export const sortArrByString = (
  dataArr = [],
  fieldName = "",
  sortBy = "desc"
) => {
  if (sortBy == "asc") {
    return dataArr.sort((a, b) =>
      a[fieldName] &&
        b[fieldName] &&
        a[fieldName].toUpperCase() > b[fieldName].toUpperCase()
        ? 1
        : -1
    );
  } else {
    return dataArr.sort((a, b) =>
      a[fieldName] &&
        b[fieldName] &&
        a[fieldName].toUpperCase() > b[fieldName].toUpperCase()
        ? -1
        : 1
    );
  }
};

export const createNewNumber = (queObj) => {
    if (queObj.includes(".")) {
      // Split the string into two parts at the decimal point
      const parts = queObj.split(".");
      //console.log(parts);

      // Check if there is a part after the decimal point
      if (parts.length === 2) {
        // Return the digits after the decimal point as a string
        return parts[1];
      }
    }

    // If there are no digits after the decimal point, return an empty string
    return "";
  }; 


export const sortDataMain = (data, dataField = "sequence") => {
  //console.log("data", data);
  let dataSort = data.sort((a, b) => a[dataField] - b[dataField]);
  // let dataSort = data.sort((a, b) => a.created - b.created);
  //console.log("dataSort", dataSort)

  dataSort.forEach((row) => {
    row.question.sort((a, b) => {
      let numA = a.sequence.split(".").map(Number);
      let numB = b.sequence.split(".").map(Number);
      for (var i = 0; i < Math.max(numA.length, numB.length); i++) {
        var partA = numA[i] || 0; // Use 0 if the part doesn't exist
        var partB = numB[i] || 0;

        if (partA < partB) {
          return -1; // a should come before b
        }
        if (partA > partB) {
          return 1; // a should come after b
        }
      }
      return 0;
    });
    row.question.forEach((value) => {
      value.skills.sort((a, b) => a[dataField] - b[dataField]);
    })
    //console.log("dataSort", dataSort);

  })
  //PREVIOUS DATA STRUCTURE SORTING
  /*  dataSort.forEach((value) => {
     value.sub_theme.sort((a, b) => a[dataField] - b[dataField]);
 
     value.sub_theme.forEach((row) => {
       //row.question.sort((a, b) => a[dataField] - b[dataField]); 
       row.question.sort((a, b) => {
         let numA = a.sequence.split(".").map(Number);
         let numB = b.sequence.split(".").map(Number);
         for (var i = 0; i < Math.max(numA.length, numB.length); i++) {
           var partA = numA[i] || 0; // Use 0 if the part doesn't exist
           var partB = numB[i] || 0;
 
           if (partA < partB) {
             return -1; // a should come before b
           }
           if (partA > partB) {
             return 1; // a should come after b
           }
         }
         return 0;
       });
     });
   });  */
  //console.log("dataSort", dataSort);
  return dataSort;
};

// Make first letter capital and rest small letters
export const capitalizeFirstLetter = (string) => {
  const str = string.trim();
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export const getPillarBgColorByIndex = (index) => {
  const COLORS = [
    'bg-[#b1d100]',
    'bg-[#f75e00]',
    'bg-[#6519ff]',
    'bg-[#991b1b]',
    'bg-[#76527f]',
    'bg-[#831843]',
    'bg-[#50939d]',
    'bg-[#009fdc]',
  ]

  return COLORS[index]
}; 