import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from "recharts";

const CustomizedLabel = (props) => {
  const { x, y, width, fill, value } = props;
  return (
    <text
      x={x + width + 10}
      y={y}
      dy={20}
      fontSize="16"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

function CustomizedTick(props) {
  const { x, y, cx, cy, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        y={y + (y - cy) / 6 - 20}
        x={x + (x - cx) / 2}
        fill="#666"
        fontSize={"0.9rem"}
      >
        <tspan textAnchor="middle" x="0">
          Line 1
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          Line 2
        </tspan>
        <tspan textAnchor="middle" x="0" dy="40">
          Line 3
        </tspan>
      </text>
    </g>
  );
}

const SampleBarChart = (props) => {
  const { id, data, key1, key1Title, legendText } = props;

  return (
    <div>
      <BarChart
        id={id}
        height={480}
        width={650}
        data={data}
        layout="vertical"
        barCategoryGap={1}
        margin={{ top: 5, right: 20, left: 5, bottom: 5 }}
      >
        <XAxis type="number" hide />
        <YAxis type="category" width={160} dataKey="skillTitle" />
        <Bar
          isAnimationActive={false} // Disable this to redner in react-pdf
          dataKey={key1}
          barSize={25}
          fill="#065985"
        >
          <LabelList dataKey={key1} position="right" />
        </Bar>
        <Legend payload={[{ value: legendText, type: "line", id: { id } }]} />
      </BarChart>
    </div>
  );
};

export default SampleBarChart;
