import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  SUBMIT_STATUS,
  FEEDBACK_STATUS,
  TABLE_QUESTION,
  TABLE_ATTRIBUTE_QUESTION,
  UPDATE_STATUS,
  PILLAR_TITLE_PREFIX,
} from "../../constants";
import { submitFeedback, updateFeedback } from "../../service";
import BackdropLoader from "../common/BackdropLoader";
import { getUser, isAdminRole } from "../../service/Auth";
import {
  FEEDBACK_ROUTE,
  PILLAR_SELECT,
  PILLAR_SELECT_EDIT,
  WIZARD_ROUTE,
} from "../../constants/Routes";

import { Steps } from "../common/Steps";
import Slider from "../common/Slider";
import EditableText from "../common/EditableText";
import { IconEye } from "../common/IconEye";
import { IconPencil } from "../common/IconPencil";
import { themeDefault } from "../../constants/themeSettings";
import TextCard from "../common/TextCard";
import { createNewNumber } from "../utility/index";
import ActivityCards from "./ActivityCards";
import AttributeCards from "./AttributeCards";

// This set to check how much % card should be answered (1%-100%)
const CARDS_MIN_COMPLETED_PERCENTAGE = 1;

// This set to hadle how many card should be filled 100% to submit feedback (min 1 - max All)
const NO_OF_CARDS_COMPULSORY = 1; // Number of 100% completed cards

const FORMTITLE_TASK =
  "SELF-REFLECTION PROCESS – FOCUS ON TASKS AND ACTIVITIES";
const FORMTITLE_ATTR =
  "SELF-REFLECTION PROCESS – FOCUS ON ATTRIBUTES AND PERSONAL QUALITIES";

const ERROR_MESSAGE = "Error while uploading your data. Please contact Admin.";
const SUBMIT_SUCCESS_MESSAGE =
  "You have successfully completed the self-reflection process and can now download your sport volunteering profile.";
const SAVE_CONTINUE_SUCCESS_MESSAGE =
  "Your answers have been saved successfully!";
const UPLOAD_MESSAGE = "Uploading your data. Please wait...";

const styles = {
  divider_line: "border-b border-50",
};

const FORM_BG_COLOR = "bg-slate-50";

const stickyClassHeader = "sticky top-10 z-40";
const stickyClassSteps = "sticky top-24 z-40";
const stickyClassEditEye = "fixed top-24 right-1 z-50";
const stickyClassPreviousStepButton = "fixed bottom-4 right-12 z-50";

// To show question number w.r.t Selected pillar
let questionNumber = 1;

const NewFeedbackForm = (props) => {
  const {
    data = [],
    defaultFormData = {},

    activeStepIndex,
    selectedStepData = {},
    handleOnStepClick,

    handleOnTextChange,
    handleOnEnterSave,
    newQuestionText,
    showNewQuestionBlockForId,
    handleNewQuestionTextChange,
    handleNewQuestionSave,
    handleDiscardNewQuestion,
    handleShowNewQuestionClick,
    isEditModeEnable,
    handleOnEditModeClick,
    createQuestionSequence,
  } = props;

  const [formData, setFormData] = useState(defaultFormData || {});

  const [isLoading, setLoader] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [alertMesssage, setAlertMesssage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [severity, setSeverity] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeInputBox, setActiveInputBox] = useState(null);
  const [exit, setExit] = useState(null);
  const [isAttributeCardEnable, setAttributeEnable] = useState(false);
  const [isContToAttribute, setContToAttribute] = useState(false);
  const prevInputValueRef = useRef(null);
  const [isMozilla, setIsMozilla] = useState(false);

  const navigate = useNavigate();

  const navigateTo = (path) => navigate(path);

  // Acticate/set the 1st partial filled pillar on page load.
  useEffect(() => {
    if (formData?.feedback && formData.feedback.length) {
      const partialIndex = formData.feedback.findIndex((data, index) => {
        return getPillarCompletionPercentage(data) < 100;
      });

      if (partialIndex > 0) {
        // Set attribute page enable on load if current found current index for attributeTheme
        if (formData.feedback[partialIndex].___class === "AttributeTheme") {
          setAttributeEnable(true);
        }
        onStepClickChange(partialIndex, formData.feedback[partialIndex]);
      }
    }
  }, []);

  const handleCancelLogic = (
    tableName,
    columnName,
    subThemeindex,
    queIndex
  ) => {
    // Based on clicked text update selectedStep object state
    if (tableName === TABLE_QUESTION) {
      console.log(
        "if",
        selectedStepData.sub_theme[subThemeindex].question[queIndex]
      );
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMozilla(userAgent.includes("Firefox"));
  }, []);

  const handleSaveClick = () => {
    // Save the input value and exit edit mode
    setIsEditing(false);
    setActiveInputBox(null);
  };

  const handleCancelClick = () => {
    // Discard changes and exit edit mode

    setIsEditing(false);
    setActiveInputBox(null);
  };

  // Return true if form is submitted
  const isStatusSubmitted = () => {
    return formData["status"] === SUBMIT_STATUS;
  };

  const onTextChange = (
    objectId,
    tableName,
    columnName,
    value,
    activeStepIndex,
    queIndex
  ) => {
    handleOnTextChange(
      objectId,
      tableName,
      columnName,
      value,
      activeStepIndex,
      queIndex
    );
  };

  const handleEditPillarSelection = () => {
    // navigate(`/${PILLAR_SELECT_EDIT}/${formData.objectId}`,{state: formData});
    if (formData && formData.objectId) {
      navigate(`/pillar/edit/${formData.objectId}`, {
        state: { feedbackObject: formData },
      });
    } else {
      const editableFormData = {};
      editableFormData.feedback = data;
      // pass isNewFeedback flag if feedback is just started and not saved
      navigate(`/pillar/edit/new`, {
        state: { feedbackObject: editableFormData, isNewFeedback: true },
      });
    }
  };

  // return total number of questions.
  const countTotalQuestions = (arr) => {
    const count =
      arr &&
      arr.question.filter((que) => {
        if (que) return "title" in que;
      });

    return (count && count.length) || 0;
  };

  // return pillar completion percentage
  const getPillarCompletionPercentage = (step) => {
    let pillarCompletPercent = 0;
    const formDataPillars = (formData && formData.feedback) || [];

    const pillarObj = formDataPillars.filter(
      (pillar) => pillar.sequence === step.sequence
    )[0];

    if (pillarObj) {
      const themeTotalQuestions = countTotalQuestions(pillarObj);
      // console.log("themeTotalQuestions", themeTotalQuestions);
      pillarCompletPercent =
        Math.round((pillarObj.answeredQuestion / themeTotalQuestions) * 100) ||
        0;
    }
    return pillarCompletPercent;
  };

  const shoAddRemovePillarIcon = () => {
    return (
      <div
        className="flex justify-center items-center bg-blue-100 cursor-pointer px-2 tooltip tooltip-left"
        data-tip={"Please click here to add or delete new role(s)"}
        onClick={handleEditPillarSelection}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-7 h-7 hover:scale-125 hover:text-sky-800 transition delay-150 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </div>
    );
  };

  const showAttributeView = () => {
    const attributeCardData = data.filter(
      (obj) => obj.___class === "AttributeTheme"
    );
    const activityCardData = data.filter((obj) => obj.___class === "theme");
    const attrCardIndex = activityCardData.length;

    // Attribute index will be +1 of last activity index
    return (
      <>
        {attributeCardData.map((step, index) => {
          return (
            <AttributeCards
              step={step}
              index={attrCardIndex}
              isAttributeCardEnable
              data={data}
              stepCompletPercentage={getPillarCompletionPercentage(step)}
              activeStepIndex={activeStepIndex}
              selectedStepData={selectedStepData}
              handleOnStepClick={onStepClickChange}
            />
          );
        })}
      </>
    );
  };

  const showActivityView = () => {
    const activityCardData = data.filter((obj) => obj.___class === "theme");

    return (
      <>
        {activityCardData.map((step, index) => {
          return (
            <ActivityCards
              step={step}
              index={index}
              data={data}
              stepCompletPercentage={getPillarCompletionPercentage(step)}
              activeStepIndex={activeStepIndex}
              selectedStepData={selectedStepData}
              handleOnStepClick={onStepClickChange}
            />
          );
        })}
      </>
    );
  };

  const stepContainer = () => {
    const themeAnsweredQuestions =
      (formData &&
        formData.feedback &&
        formData.feedback[activeStepIndex] &&
        formData.feedback[activeStepIndex].answeredQuestion) ||
      0;

    const themeTotalQuestions = countTotalQuestions(data[activeStepIndex]);
    let isAllCardsAnswered = isAllActivityCardsAnswered(formData?.feedback);

    return (
      <div className={`${stickyClassSteps}`}>
        <div className={`border-slate-200 bg-slate-200 border rounded-md`}>
          <div
            className={`flex justify-around items-center px-2 ${
              isAttributeCardEnable && "px-0"
            }`}
          >
            {isAllCardsAnswered && isAttributeCardEnable
              ? showAttributeView()
              : showActivityView()}
          </div>
        </div>
        <div className={`flex justify-end pl-1`}>
          {shoAddRemovePillarIcon()}
          <div className="border-l-2 border-slate-400"></div>
          <p className="bg-blue-100 px-2">
            {`You have answered: ${themeAnsweredQuestions} / ${themeTotalQuestions}`}
          </p>
        </div>
      </div>
    );
  };

  const formHeadNote = () => {
    const taskDesc = (
      <span>
        <p>
          You are now asked series of questions about how CONFIDENT you feel
          carrying out the following tasks.
        </p>
        <p className="pt-4 pb-1">THE SCALE:</p>
        <div className="pl-6">
          No answer means you have not done the task.
          <br />
          1 means you have done the task but do not feel confident doing it.
          <br />
          2 means you feel slightly confident but usually need help.
          <br />
          3 means you usually feel confident and rarely need help.
          <br />
          4 means you feel very confident doing it and do not need help.
          <br />
        </div>
      </span>
    );
    const attrDesc = (
      <span>
        <p>
          You are now asked to respond to a series of questions to identify the
          attributes and personal qualities you have demonstrated in your sport
          volunteering experience(s).
        </p>
        <p className="pt-2">
          Please answer all the questions below, showing your LEVEL OF AGREEMENT
          with the following statements.
        </p>
        <p className="pt-2 pb-1">THE SCALE:</p>
        <div className="pl-6">
          1 = a very low level of agreement and
          <br />4 = a very high level of agreement.
        </div>
      </span>
    );

    return (
      <div className="flex justify-start text-left font-semibold pt-4 px-4">
        {isAttributeCardEnable ? attrDesc : taskDesc}
      </div>
    );
  };

  const formHeading = () => {
    const isBackBtnEnable = activeStepIndex > 0;

    return (
      <div
        className={`${stickyClassHeader} flex justify-center items-center bg-slate-200 pt-1 px-8`}
      >
        <div className="">
          {isBackBtnEnable && (
            <button
              className={`flex px-3 py-1 items-center rounded text-white font-semibold text-sm ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover} tooltip tooltip-right`}
              data-tip="Go to Previous Role"
              onClick={handleBackStepBtnClick}
            >
              <svg
                class="w-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Back
            </button>
          )}
        </div>

        <div
          className={`py-5 pb-6 text-2xl font-bold tracking-wide ${themeDefault.text_color_dark} flex-1 justify-center`}
        >
          {isAttributeCardEnable ? FORMTITLE_ATTR : FORMTITLE_TASK}
        </div>
      </div>
    );
  };

  function countQuestions(arr) {
    //console.log("arr", arr);
    const count = arr.question.filter((que) => {
      return que.value > 0;
    });
    return count.length;
  }

  const handleSelection = (value) => {
    // Old data stucture - feedback: {'key':value,'key':value,...}
    // setFormData({ ...formData, [name]: value });
    let countedQuestion = countQuestions(value.feedback[activeStepIndex]);
    let totalQuestion = countTotalQuestions(value.feedback[activeStepIndex]);
    let percentComplete = Math.round((countedQuestion / totalQuestion) * 100);

    value.feedback[activeStepIndex]["answeredQuestion"] = countedQuestion;
    value.feedback[activeStepIndex]["percentComplete"] = percentComplete;

    // New data stucture - feedback: {[],[],[],..}
    setFormData({ ...value });
  };

  // Handle slider value change
  const onSliderChange = (event, question, queIndex) => {
    const value = event.target.value;

    let questionSelected = question;

    // TODO: Refactor this code, merge feedback:{} directly to existing obj
    if (Object.keys(formData).length === 0) {
      let obj = { feedback: [...data] };
      obj.feedback[activeStepIndex].question[queIndex]["value"] = value;
      handleSelection(obj);
    } else if (formData.feedback === null) {
      let objCheck = { ...formData };
      objCheck.feedback = [...data];
      objCheck.feedback[activeStepIndex].question[queIndex] = {
        ...questionSelected,
      };
      handleSelection(objCheck);
    } else {
      let obj1 = { ...formData };
      questionSelected["value"] = value;

      obj1.feedback[activeStepIndex].question[queIndex] = {
        ...questionSelected,
      };

      handleSelection(obj1);
    }
  };

  const noDataBlock = (noDataMsg = "No data found !") => {
    return (
      <div className="font-bold text-slate-400 text-center py-8">
        {noDataMsg}
      </div>
    );
  };

  const getSelectedOption = (question, queIndex) => {
    if (Object.keys(formData).length === 0 || formData.feedback === null) {
      const selectedArr = 0;
      return selectedArr;
    } else {
      const selectedArr =
        formData?.feedback[activeStepIndex].question[queIndex]?.value;
      return selectedArr;
    }
  };

  const getAddNewQuestionBlock = (subtheme) => {
    return (
      <div className=" text-center p-5">
        <button
          type="button"
          onClick={() => handleShowNewQuestionClick(subtheme.objectId)}
          className={`w-[9rem] px-1 py-2 rounded text-white font-semibold text-xs ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`}
        >
          Add New Question
        </button>
      </div>
    );
  };

  const addNewQuestionTextBox = (selectedStepData = {}, queIndex) => {
    let check = createQuestionSequence(selectedStepData);

    return (
      <div className="border border-sky-600 bg-sky-100 m-3">
        <p className="text-[15px] pl-12 py-5">
          <span className="flex flex-row items-baseline ">
            {`T${createNewNumber(check)}. `}
            {
              <EditableText
                id={`${TABLE_QUESTION - selectedStepData.id}`}
                autoFocus={true}
                name={`text-${TABLE_QUESTION}`}
                placeholder={`Add a new question for ${selectedStepData.title}`}
                disabled={!isEditModeEnable}
                style={`text-[15px] ${FORM_BG_COLOR}`}
                value={newQuestionText}
                handleOnEnterSave={(val) =>
                  handleNewQuestionSave(val, selectedStepData)
                }
                handleTextChange={(val) => handleNewQuestionTextChange(val)}
              />
            }
            <div className="flex px-2">
              <button
                className={`px-2 h-8 basis-[19.2857143%] rounded text-white font-semibold text-sm ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`}
                onClick={() =>
                  handleNewQuestionSave(newQuestionText, selectedStepData)
                }
              >
                Save
              </button>
              <button
                className={`px-2 ml-1 h-8 basis-[19.2857143%] rounded text-white font-semibold text-sm bg-red-700 hover:bg-red-800 `}
                onClick={handleDiscardNewQuestion}
              >
                Exit
              </button>
            </div>
          </span>
          <p className="pl-4 pt-1 text-xs">
            *Press Enter to save or click 'save' button.
          </p>
        </p>
      </div>
    );
  };

  const getSubThemeBlock = () => {
    // Return if sub_theme data not configured
    if (!selectedStepData.question || !selectedStepData.question.length) {
      return noDataBlock(
        `No sub themes configure for ${selectedStepData.title}`
      );
    }

    const isAttribute = selectedStepData.___class === "AttributeTheme";

    // Reset question number to 1 while re-rendering
    questionNumber = 1;
    const enableNewQuestionBlock =
      showNewQuestionBlockForId &&
      showNewQuestionBlockForId === selectedStepData.objectId;

    return (
      <div id="questionSectionContainer" className="pt-3">
        {selectedStepData?.question?.map((question, queIndex) => {
          return (
            <div
              id={`subtheme-${queIndex}`}
              className="bg-slate-50 rounded-lg px-3"
            >
              <div className="px-3">
                <h3 className="font-bold text-lg text-slate-600">
                  <div
                    className="relative flex-row items-baseline"
                    /* style={{ flexWrap: "wrap" }} */
                  >
                    <div
                      style={{
                        position: "relative",
                        left: "-12px",
                        top: "32px",
                        marginLeft: "3px",
                        paddingLeft: "10px",
                      }}
                      className="text-[16px] bg-slate-50"
                    >{`${isAttribute ? "A" : "T"}${createNewNumber(
                      question.sequence
                    )}.`}</div>
                    <div
                      style={{
                        position: "relative",
                        left: "27px",
                        paddingRight: "2px",
                      }}
                    >
                      {
                        <EditableText
                          id={question.objectId + queIndex}
                          setIsEditing={setIsEditing}
                          setActiveInputBox={setActiveInputBox}
                          activeInputBox={activeInputBox}
                          tableQuestion={
                            selectedStepData.sequence !== "8"
                              ? TABLE_QUESTION
                              : TABLE_ATTRIBUTE_QUESTION
                          }
                          queIndex={queIndex}
                          columnName={"title"}
                          handleCancelLogic={handleCancelLogic}
                          prevInputValueRef={prevInputValueRef}
                          name={`text-${
                            selectedStepData.sequence !== "8"
                              ? TABLE_QUESTION
                              : TABLE_ATTRIBUTE_QUESTION
                          }`}
                          placeholder="Question text..."
                          disabled={!isEditModeEnable}
                          style={`text-[18px] font-semibold ${FORM_BG_COLOR}`}
                          value={question.title}
                          handleOnEnterSave={(val) =>
                            handleOnEnterSave(
                              val,
                              question.objectId,
                              selectedStepData.sequence !== "8"
                                ? TABLE_QUESTION
                                : TABLE_ATTRIBUTE_QUESTION,
                              "title"
                            )
                          }
                          handleTextChange={(value) => {
                            onTextChange(
                              question.objectId,
                              TABLE_QUESTION,
                              "title",
                              value,
                              activeStepIndex,
                              queIndex
                            );
                          }}
                        />
                      }
                    </div>
                  </div>
                </h3>
                {!isAttribute && (
                  <div className="text-sm text-slate-700">
                    <div
                      className="relative flex flex-row items-baseline"
                      style={{ flexWrap: "wrap", left: "26px" }}
                    >
                      {
                        <EditableText
                          id={question.objectId}
                          description={true}
                          setIsEditing={setIsEditing}
                          setActiveInputBox={setActiveInputBox}
                          activeInputBox={activeInputBox}
                          name={`text-${
                            selectedStepData.sequence !== "8"
                              ? TABLE_QUESTION
                              : TABLE_ATTRIBUTE_QUESTION
                          }`}
                          placeholder="asd"
                          disabled={!isEditModeEnable}
                          style={`text-[15px] ${FORM_BG_COLOR}`}
                          value={question.description}
                          handleOnEnterSave={(val) =>
                            handleOnEnterSave(
                              val,
                              question.objectId,
                              selectedStepData.sequence !== "8"
                                ? TABLE_QUESTION
                                : TABLE_ATTRIBUTE_QUESTION,
                              "description"
                            )
                          }
                          handleTextChange={(value) => {
                            onTextChange(
                              question.objectId,
                              TABLE_QUESTION,
                              "description",
                              value,
                              activeStepIndex,
                              queIndex,
                              null
                            );
                          }}
                        />
                      }
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-center mt-8">
                <Slider
                  handleChange={(e) => onSliderChange(e, question, queIndex)}
                  selectedValue={getSelectedOption(question, queIndex)}
                  /*  isDisable={isStatusSubmitted()} */
                  selectedStepData={selectedStepData}
                  activeStepIndex={activeStepIndex}
                  style={"w-10/12"}
                  isMozilla={isMozilla}
                />
              </div>
            </div>
          );
        })}
        {isEditModeEnable &&
          enableNewQuestionBlock &&
          addNewQuestionTextBox(selectedStepData)}

        {isEditModeEnable && getAddNewQuestionBlock(selectedStepData)}
      </div>
    );
  };

  // Move to next step
  const moveToNextStep = () => {
    // Move to attribute section
    if (isContToAttribute) {
      setAttributeEnable(true);
    }
    onStepClickNew(activeStepIndex + 1);
  };

  const onStepClickNew = (index) => {
    handleOnStepClick(index);
    questionNumber = 1;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const onStepClickChange = (index, data) => {
    let check = isAllActivityCardsAnswered(formData?.feedback);
    if (isEditModeEnable) {
      handleOnStepClick(index);
      questionNumber = 1;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      if (formData?.status === "Submitted") {
        handleOnStepClick(index);
        questionNumber = 1;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      } else {
        if (data.sequence !== "8") {
          handleOnStepClick(index);
          questionNumber = 1;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else if (check && data.sequence === "8") {
          handleOnStepClick(index);
          questionNumber = 1;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    }
  };

  const handleOnError = () => {
    setLoader(false);
    setAlertMesssage(ERROR_MESSAGE);
    setSeverity("error");
  };

  const handleOnSuccess = (msg = "Done!", modalTitle = "") => {
    setLoader(false);
    setAlertMesssage(msg);
    setSeverity("success");
    if (modalTitle) {
      setModalTitle(modalTitle);
    }
  };

  // return feedback obj which having values only
  const filterFeedbackOnlyWithValues = (row) => {
    const feedback = (row.feedback || []).map((item) => {
      const sub = (item.sub_theme || []).filter((subTheme) => {
        const index = (subTheme.question || []).filter((queValue) => {
          return !!queValue?.value;
        });
        if (index.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      if (sub.length > 0) {
        item.sub_theme = sub;
        return item;
      }
    });

    row.feedback = feedback.filter((row) => !!row);
    return row;
  };

  const onLoaderModalClose = () => {
    setAlert(false);
    setLoader(false);
    setAlertMesssage(null);
    setSeverity(null);

    // Redirect only if feedback submitted successfully.
    if (severity === "success") {
      // navigate(-1);
      // Pass current submitted form data to feedback page to automatically show map on submit
      // console.log("formData::: ", formData);
      if (formData && formData.status === SUBMIT_STATUS) {
        const filterFormData = filterFeedbackOnlyWithValues(formData);
        navigate(`/${FEEDBACK_ROUTE}`, { state: filterFormData });
      } else if (formData && exit === true) {
        const filterFormData = filterFeedbackOnlyWithValues(formData);
        navigate(`/${FEEDBACK_ROUTE}`);
      } else if (formData?.objectId) {
        //navigate(`/feedback/${formData.objectId}`);
        window.history.replaceState(null, "", `/feedback/${formData.objectId}`);
      }
      moveToNextStep();
    }
  };

  const setStateBeforeUpload = () => {
    setAlert(true);
    setAlertMesssage(UPLOAD_MESSAGE);
    setLoader(true);
  };

  // Submit form request to backend
  const onSubmit = (reqBody, isSuccessMsg, modalTitle) => {
    submitFeedback(reqBody)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          handleOnError();
        } else {
          // Update current form state to state
          setFormData({ ...formData, ...data });
          handleOnSuccess(isSuccessMsg || SUBMIT_SUCCESS_MESSAGE, modalTitle);
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        handleOnError();
      });
  };

  // Update form request to backend
  const onUpdate = (reqBody, objectId, isSuccessMsg, modalTitle) => {
    // Update current form state to state
    // setFormData({ ...formData, ...reqBody });

    updateFeedback(reqBody, objectId)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          handleOnError();
        } else {
          // console.log(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
          // Update current form state to state
          setFormData({ ...formData, ...data });
          handleOnSuccess(
            isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE,
            modalTitle
          );
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        handleOnError();
      });
  };

  const handleGoBackToActivity = () => {
    setAttributeEnable(false);
    onStepClickNew(0);
  };

  const handleBackStepBtnClick = () => {
    setAttributeEnable(false);
    onStepClickNew(activeStepIndex - 1);
  };

  const handleFormSubmit = (btnClick) => {
    const objectId = formData["objectId"];
    const formStatus = formData["status"];
    let reqBody;

    if (Object.keys(formData).length === 0) {
      formData["feedback"] = data;
      reqBody = { ...formData };
    } else {
      reqBody = { ...formData };
    }

    try {
      // Set upload flag in state on submit like [isloading,alertMsg etc]
      setStateBeforeUpload();

      if (btnClick === "submit") {
        // Form with 'submitted' status
        reqBody.status = SUBMIT_STATUS;
        reqBody.feedbackStatus = FEEDBACK_STATUS;

        if (objectId) {
          // It's an old feedback. Just update It
          onUpdate(reqBody, objectId, SUBMIT_SUCCESS_MESSAGE, "Completed");
        } else {
          // It's a new feedback. Create a new one
          onSubmit(reqBody, "Completed");
        }
      } else if (
        btnClick === "save" ||
        btnClick === "exit" ||
        btnClick === "saveAndGoToAttribute"
      ) {
        if (btnClick === "exit") {
          setExit(true);
        }
        if (btnClick === "saveAndGoToAttribute") {
          setContToAttribute(true);
        }
        // Form with 'pending' status
        reqBody.status = UPDATE_STATUS;
        /* navigate(`/feedback/${row.objectId}`) */
        if (objectId) {
          // It's an old feedback. Just update It
          onUpdate(reqBody, objectId);
        } else {
          // It's a new feedback. Create a new one
          onSubmit(reqBody, SAVE_CONTINUE_SUCCESS_MESSAGE);
        }
      }
    } catch (error) {
      console.log("error while Uploading form:", error);
      handleOnError("Error while Uploading form");
    }
  };

  const getInfoIcon = () => {
    return (
      <div className="tooltip text-indigo-400 pl-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </div>
    );
  };

  const isAllCardAnswered = (cardsData) => {
    const answeredCards = cardsData.filter(
      (obj) => obj?.percentComplete >= CARDS_MIN_COMPLETED_PERCENTAGE
    );

    // Return the length of the filtered array.
    return answeredCards.length === cardsData.length;
  };

  const isAllActivityCardsAnswered = (cardsData) => {
    const data =
      cardsData && cardsData.filter((obj) => obj.___class === "theme");

    if (data === undefined || !data.length) {
      return false;
    }

    const value = isAllCardAnswered(data);
    return value;
  };

  const checkSubmitBtnDisable = (data) => {
    if (data === undefined || !data.length) {
      return true;
    }

    // If all cards have answered enable submit button
    if (formData?.status && isAllCardAnswered(data)) {
      return false;
    } else {
      return true;
    }
  };

  const getFormActionButtons = () => {
    let isSubmitDisable = checkSubmitBtnDisable(formData.feedback);
    let isAllCardsAnswered = isAllActivityCardsAnswered(formData?.feedback);

    const disableClass = "opacity-50 disabled:cursor-not-allowed";
    const btnSize = "w-96";
    const tooltipClass = "tooltip tooltip-left";

    const nextStepBtnTooltip = !isAllCardsAnswered
      ? "You need to answer at least one statements from each activity to enable Attributes Questionnaire."
      : `Clicking on this button will save your responses and you will continue to the ATTRIBUTES/PERSONAL QUALITIES section.`;

    // Get last activity card index
    let indexOfLastActivityCard = data.length - 2 === activeStepIndex;

    const themeAnsweredQuestions =
      (formData &&
        formData.feedback &&
        formData.feedback[activeStepIndex] &&
        formData.feedback[activeStepIndex].answeredQuestion) ||
      0;
    const isSaveAndContBtnDisable = themeAnsweredQuestions < 1;

    const saveContinueBtnToolTip = isSaveAndContBtnDisable
      ? "You need to answer at least one statements to be able to save and move to the next section."
      : `Clicking on this button will save your responses for ${PILLAR_TITLE_PREFIX} and you will continue to the next section.`;

    return (
      <div className="w-10/12 flex flex-col justify-end items-end py-6 border-t border-50">
        <div
          className={`flex items-center ${tooltipClass}`}
          data-tip={`${saveContinueBtnToolTip}`}
        >
          {!indexOfLastActivityCard && !isAttributeCardEnable && (
            <>
              <button
                className={`${btnSize} px-3 py-3 mt-2 rounded text-white font-semibold text-sm ${
                  themeDefault.btn_bg_color
                } hover:${themeDefault.btn_bg_hover} ${
                  isSaveAndContBtnDisable && disableClass
                }`}
                onClick={() => handleFormSubmit("save")}
                disabled={isSaveAndContBtnDisable}
              >
                {`Save and continue to next section`}
              </button>
              {getInfoIcon()}
            </>
          )}
        </div>

        <div
          className={`flex items-center ${tooltipClass}`}
          data-tip={nextStepBtnTooltip}
        >
          {/* Show Go to attribute button */}
          {indexOfLastActivityCard && (
            <>
              <button
                className={`${btnSize} px-3 py-3 mt-2 rounded text-white font-semibold text-sm bg-green-800 hover:bg-green-700 ${
                  !isAllCardsAnswered && disableClass
                }`}
                onClick={() => handleFormSubmit("saveAndGoToAttribute")}
                disabled={!isAllCardsAnswered}
              >
                {`Next step`}
              </button>
              {getInfoIcon()}
            </>
          )}
        </div>
        {/* Go to activities section */}
        {isAttributeCardEnable && (
          <div
            className={`flex items-center ${tooltipClass}`}
            data-tip="Go back to previous activity section"
          >
            <button
              className={`${btnSize} px-3 py-3 mt-2 rounded font-semibold text-sm text-blue-600 border-2 border-blue-500 hover:bg-blue-600 hover:text-white`}
              onClick={handleGoBackToActivity}
            >
              Back to previous step
            </button>
            {getInfoIcon()}
          </div>
        )}
        <div
          className={`flex items-center ${tooltipClass}`}
          data-tip="You can save your answers now and come back later at any point of time in future to continue this session."
        >
          <button
            className={`${btnSize} px-3 py-3 mt-2 rounded font-semibold text-sm text-blue-600 border-2 border-blue-500 hover:bg-blue-600 hover:text-white`}
            onClick={() => handleFormSubmit("exit")}
          >
            Save my self-reflection and continue later
          </button>
          {getInfoIcon()}
        </div>
        {isAttributeCardEnable && (
          <div>
            <div
              className={`flex items-center ${tooltipClass}`}
              data-tip={`This is the button to submit all your answers and get your report.`}
            >
              <button
                className={`${btnSize} px-3 py-3 mt-2 rounded text-white font-semibold text-sm bg-green-700 hover:bg-green-600 ${
                  isSubmitDisable && disableClass
                }`}
                onClick={() => handleFormSubmit("submit")}
                disabled={isSubmitDisable}
              >
                Submit my responses and access to results
              </button>
              {getInfoIcon()}
            </div>
          </div>
        )}
      </div>
    );
  };

  const formBodyContainer = () => {
    const isAttribute = selectedStepData.___class === "AttributeTheme";
    const preFix_title = isAttribute
      ? ""
      : `${PILLAR_TITLE_PREFIX} 0${selectedStepData.sequence} :`;

    return (
      <h3 className="text-xl p-5 text-left">
        <div className="font-semibold">
          {`${preFix_title} ${selectedStepData.title?.toUpperCase()}`}
        </div>
        <div className="text-sm text-slate-700 pt-6">
          {isAttributeCardEnable
            ? `We encourage you to be very honest in showing how strongly you agree with the statements listed below. You must show a level of agreement for all the statements to finish the questionnaire and get your report.`
            : `We encourage you to be very honest in showing how confident you feel when carrying out the tasks and activities listed below. If you have not carried any of the tasks, please leave it as No Answer.`}
        </div>
        <div id="subThemeContainer" className="pt-7">
          {/* <div className='font-semibold text-lg'>
            {
              "You need to answer a series of 20 questions to identify potential areas of improvement. There are no right or wrong answers. Just be as honest as you can."
            }
          </div> */}
          {getSubThemeBlock()}
        </div>
      </h3>
    );
  };

  const toggleEditModeIcon = () => {
    const iconBgColor = isEditModeEnable ? "bg-yellow-300" : "bg-blue-300";
    const shadeColorFrom = isEditModeEnable
      ? "from-yellow-600"
      : "from-blue-500";
    const shadeColorTo = isEditModeEnable ? "to-gray-600" : "to-blue-600";

    return (
      <div className={`${stickyClassEditEye}`} onClick={handleOnEditModeClick}>
        <div className="relative group">
          <div
            className={`absolute -inset-1 bg-gradient-to-r ${shadeColorFrom} ${shadeColorTo} rounded-full blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200`}
          ></div>
          <div
            className={`relative p-5 ${iconBgColor} ring-8 ring-gray-900/5 rounded-full leading-none flex items-top justify-start space-x-6`}
          >
            {isEditModeEnable ? <IconPencil color={"white"} /> : <IconEye />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-2 my-1 w-full">
      {isAdminRole() && toggleEditModeIcon()}
      {formHeading()}
      {stepContainer()}
      {formHeadNote()}
      <div className="divider pt-5"></div>
      {formBodyContainer()}
      {getFormActionButtons()}
      {/* {previousStepButton()} */}
      {showAlert && (
        <BackdropLoader
          isOpen={showAlert}
          isLoading={isLoading}
          message={alertMesssage}
          modalTitle={modalTitle}
          severity={severity}
          onClose={onLoaderModalClose}
        />
      )}
    </div>
  );
};

export default NewFeedbackForm;
