import { useNavigate } from "react-router-dom";
import {
  BTN_MAPPING_TITLE,
  BTN_NEWFEEDBACK_TITLE,
  BTN_NEWFEEDBACK_TITLE_CONTINUE,
  SUBMIT_STATUS,
} from "../../constants";
import { themeDefault } from "../../constants/themeSettings";
import { APP_IMAGE_BACKGOUND } from "../../images";
import { useEffect, useState } from "react";
import { getHistory } from "../../service";
import Spinner from "../../components/common/Spinner";
import { WIZARD_ROUTE } from "../../constants/Routes";
import AppFooter from "../../components/common/AppFooter";

const BTN_MAPPING_DESC =
  "I would like to access my previous self-reflection results";

const BTN_NEWFEEDBACK_DESC =
  "Discover the valuable competencies, skills and personal qualities gained through your volunteering experiences to support your professional or volunteering career.";

const descriptionText = (text) => {
  return (
    <div className="text-lg font-medium">
      <h1>{text}</h1>
    </div>
  );
};

const mappingButton = (title, onClick) => {
  return (
    <>
      <button
        className={`flex items-center justify-center text-lg h-16 w-auto bg-transparent hover:bg-sky-700 ${themeDefault.text_color_dark} font-semibold hover:text-white py-2 px-4 border border-[#38577f] hover:border-transparent rounded-md`}
        onClick={onClick}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          style={{ marginLeft: "20px" }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoaderType, setLoaderType] = useState(false); // check LoaderTypes
  const [isFeedbackHistory, setisFeedbackHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const btn_class = "flex flex-col p-8 space-y-8";

  useEffect(() => {
    fetchHistoryData();
  }, []);

  const fetchHistoryData = async () => {
    try {
      // Fetch history page data / api logic
      setLoaderType(true);
      getHistory().then((data) => {
        if (!data.errorData && data.length) {
          // Hide History page navigation
          const isSubmitted = data[0]?.status === SUBMIT_STATUS;
          if (isSubmitted) {
            setisFeedbackHistory(true);
          }

          setHistoryData(data);
        }
        setLoaderType(false);
      });
    } catch (err) {
      setLoaderType(false);
      console.log("Error ", err);
    }
  };

  const mainBlock = () => {
    return (
      <div className="">
        <h2 className="text-center text-3xl text-[#38577f] font-semibold xl:text-4xl">
          SELF-REFLECTION TOOL FOR SPORTS VOLUNTEERS
        </h2>
        <h3 className="mt-4 mb-6 text-md font-semibold text-[#38577f] xl:text-xl">
          An easy-to-use tool to analyse your volunteering experiences in the
          sport sector.
        </h3>
        {!isFeedbackHistory && (
          <div className={btn_class}>
            {descriptionText(BTN_NEWFEEDBACK_DESC)}
            {historyData[0] === undefined
              ? mappingButton(BTN_NEWFEEDBACK_TITLE, onNewFeedbackClick)
              : mappingButton(
                  BTN_NEWFEEDBACK_TITLE_CONTINUE,
                  onNewFeedbackClick
                )}
          </div>
        )}
        {isFeedbackHistory && (
          <div className={btn_class}>
            {descriptionText(BTN_MAPPING_DESC)}
            {mappingButton(BTN_MAPPING_TITLE, onMappingClick)}
          </div>
        )}
      </div>
    );
  };

  const onMappingClick = () => {
    navigate("/feedbacks");
  };
  const onNewFeedbackClick = () => {
    if (historyData[0] === undefined) {
      navigate("pillar");
    } else {
      navigate(`/feedback/${historyData[0].objectId}`);
    }
  };

  // return loader if it's
  if (isLoaderType) {
    return (
      <>
        <div className="flex h-screen absolute -top-10 left-1/2 z-50">
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="lg:flex w-full">
        <div className="hidden lg:flex bg-indigo-100 flex-1 h-screen">
          <img
            src={APP_IMAGE_BACKGOUND}
            className="object-cover w-full h-full"
            alt="introduction page image"
          />
        </div>
        <div className="lg:w-6/12 xl:h-screen flex items-center justify-center">
          <div className="mt-8 px-12 sm:px-24 md:px-48 lg:px-12 xl:px-20 xl:max-w-2xl">
            {mainBlock()}
          </div>
        </div>
      </div>
      <div className="pt-6">
        <AppFooter />
      </div>
    </div>
  );
};
export default Dashboard;
