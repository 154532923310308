import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  BarChart,
  Bar,
  Label,
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from "recharts";
import { themeDefault } from "../../constants/themeSettings";

const DOMAIN_RANGE_DEFAULT = [1, 4];

function splitByWordCount(str, count) {
  var arr = str.split(' ')
  var r = [];
  while (arr.length) {
    r.push(arr.splice(0, count).join(' '))
  }
  return r;
}

// Remove string element after 4th index and append '...' to 4th string ele. 
const longStringAppendDot = (strArr) => {
  let newStrArr = strArr.slice(0, 3)
  newStrArr.splice(3, 0, `${strArr[3]} ...`)
  return newStrArr
}

function customTick({ payload, x, y, cx, cy, textAnchor, stroke, radius }) {
  // Split long text into multiple lines
  // const strArr = payload.value.match(/.{1,22}/g)
  let strArr = splitByWordCount(payload.value, 2);

  // apeend '...' for long string
  strArr = strArr.length > 4 ? longStringAppendDot(strArr) : strArr

  return (
    <g
      className="recharts-layer recharts-polar-angle-axis-tick"
    >
      <text
        radius={radius}
        stroke={stroke}
        y={y + (y - cy) / 6 - 20}
        x={x + (x - cx) / 2}
        className="recharts-text recharts-polar-angle-axis-tick-value"
        text-anchor={textAnchor}
        fill={themeDefault.grayDark}
        fontSize={'0.9rem'}
      >
        {strArr.map(str =>
          <tspan x={x} dy="1.1em">
            {str}
            {/* {str.toLowerCase()} */}
            {/* {payload.value.length < textLength ? payload.value : `${payload.value.substring(0, textLength)}...`} */}
          </tspan>)}
      </text>
    </g>
  );
}


const SpiderChart = (props) => {
  const { data = [], dataKey, color, dataKeyVal, radarName = '', domainRange = DOMAIN_RANGE_DEFAULT, toolTipText = '' } = props;

  if (!data || !data.length) return <div>No Data found for Graph</div>

  const renderCusomizedLegend = (props) => {
    return (
      <div className="customized-legend text-white">
        ''
      </div>
    )
  }

  const graphBlock = () => {

    return (
      <RadarChart
        // outerRadius={100}
        data={data}
        width={750}
        height={450}
        margin={{ bottom: 0, right: 70 }}
      // margin={{ top: 10, right: 20, bottom: 10, left: 10 }}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey={dataKey} tick={customTick} />
        <PolarRadiusAxis angle={30} domain={domainRange} />
        <Radar
          name={radarName}
          dataKey={dataKeyVal}
          stroke={color}
          fill={color}
          fillOpacity={0.6}
        />
        {/* <Legend /> */}
        <Legend
          // wrapperStyle={{ bottom: -35, right: 0 }}
          verticalAlign="top"
          align="right"
          content={renderCusomizedLegend}
        />
        <Tooltip cursor={{ stroke: "red", strokeWidth: 2 }} />
      </RadarChart>
    );
  };

  const showAboutGraphInfo = () => {
    return (
      <div className="tooltips flex justify-center items-center cursor-pointer group mt-3" data-tip={toolTipText}>
        <svg
          class="w-5 h-5 fill-yellow-100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
        <p className={`text-sm pl-1 text-yellow-600`}>{toolTipText}</p>
      </div>
    )
  }
  return (
    <ResponsiveContainer width="100%" minHeight="480px" minWidth="700px">
      <>
        <div
          // className="flex justify-center"
          id={`spiderChart-${dataKey}`}
          style={{
            padding: "10px",
            width: "100%",
            height: "480px",
            backgroundColor: "#fff",
          }}
        >
          {graphBlock()}
        </div>
        {showAboutGraphInfo()}
      </>


    </ResponsiveContainer>
  )

};
export default SpiderChart;
