import { TABLE_QUESTION } from "../constants";
import {
  answers_api,
  topics_api,
  update_answers_api,
  get_answer_api,
  category_api,
  groups_api,
  theme_api,
  question_api,
  attribute_question_api,
  question_view_api,
  sub_theme_api,
  question_by_id_api,
   attribute_by_id_api,
  subtheme_by_id_api,
  updateUser_api,
  users_api,
  theme_by_title_api,
  sub_theme_by_title_api,
  delete_answer_by_id__api,
  attributr_api,
  relation_api,
} from "../constants/api";
import { getAuthToken, setUserAuth } from "./Auth";

const PAGINATION = "?pageSize=100";

const validateResponse = (response) => {
  if (response.status === 401 || response.status === 302) {
    setUserAuth(null);
    //window.reload();
  }
  return response.json();
};

const getRequestHeader = () => {
  const headerObj = {
    "Content-Type": "application/json",
    "user-token": getAuthToken(),
  };
  return headerObj;
};

/* export const getTopics = async () => {
  try {
    const response = await fetch(topics_api, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
}; */

export const getTheme = async () => {
  try {
    const response = await fetch(theme_api, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

// Returns questions data from question table
export const getQuestion = async () => {
  const sortBy = 'sortBy=`created` desc'
  try {
    const response = await fetch(`${question_view_api}?${sortBy}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};


export const getAttribute = async () => {
  const sortBy = "sortBy=`created` desc";
  try {
    const response = await fetch(`${attributr_api}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

// Api to Add new question
export const addNewQuestion = async (new_question = "", sequence) => {
  const reqBody = {
    title: new_question,
    description: new_question,
    sequence: sequence,
  };

  try {
    const response = await fetch(question_api, {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestion: ", error);
  }
};

export const addNewAttributeQuestion = async (new_question = "", sequence) => {
  const reqBody = {
    title: new_question,
    description: new_question,
    sequence: sequence,
  };

  try {
    const response = await fetch(attribute_question_api, {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestion: ", error);
  }
};

// Update Question table
export const updateQuestionApi = async (id, value = "", columnName = "") => {
  const reqBody = { [columnName]: value, 'objectId': id };

  try {
    const response = await fetch(question_by_id_api(id), {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestion: ", error);
  }
};

//Update Attribute table
export const updateAttributeApi = async (id, value = "", columnName = "") => {
  const reqBody = { [columnName]: value, objectId: id };

  try {
    const response = await fetch(attribute_by_id_api(id), {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestion: ", error);
  }
};

// Update Sub-theme table
export const updateSubThemeApi = async (id, value = "", columnName = "") => {
  const reqBody = { [columnName]: value, 'objectId': id };

  try {
    const response = await fetch(subtheme_by_id_api(id), {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestion: ", error);
  }
};


// Api to delete question by id
export const deleteQuestionById = async (id) => {
  try {
    const response = await fetch(question_by_id_api(id), {
      method: "DELETE",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

// Api to adds new relations of questions
export const addNewQuestionRelation = async (
  sub_theme_id = "",
  questionId = ""
) => {
  const api_url = `${sub_theme_api}/${sub_theme_id}/${TABLE_QUESTION}`;

  const reqBody = (questionId && [questionId]) || [];

  try {
    const response = await fetch(api_url, {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestionRelation: ", error);
  }
};

export const addAttributeRelation = async (attributeThemeId, attributeQuestionId) => {
  const api_url =
    "https://regnantplanes.backendless.app/api/services/apiServices/putAttributeThemeRelation";

  const reqBody = {
    attributeThemeId: attributeThemeId,
    attributeQuestionId: attributeQuestionId,
  };

  try {
    const response = await fetch(api_url, {
      method: "PUT",
      /*  headers: getRequestHeader(), */
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestionRelation: ", error);
  }
};

export const addRelation = async (themeObjectId, questionObjectId) => {
  const api_url =
    "https://regnantplanes.backendless.app/api/services/apiServices/putRelation";

  const reqBody = { themeObjectId: themeObjectId, questionObjectId: questionObjectId};

  try {
    const response = await fetch(api_url, {
      method: "PUT",
      /*  headers: getRequestHeader(), */ 
      body: JSON.stringify(reqBody)
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error while calling addNewQuestionRelation: ", error);
  }
};

/* export const getGroups = async () => {
  try {
    const response = await fetch(groups_api, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
}; */

export const getHistory = async () => {
  try {
    const response = await fetch(`${answers_api}${PAGINATION}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const getFeedbackById = async (id) => {
  try {
    const response = await fetch(get_answer_api(id), {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const submitFeedback = async (reqBody) => {
  try {
    const response = await fetch(answers_api, {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
      // body: reqBody,
    });
    return validateResponse(response);
  } catch (error) {
    console.log(
      "Error while submitting feedback. Error in (submitFeedback) : ",
      error
    );
  }
};

export const updateFeedback = async (reqBody, id) => {
  try {
    const response = await fetch(update_answers_api(id), {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log(
      "Error while submitting feedback. Error in (submitFeedback) : ",
      error
    );
  }
};

export const getCategories = async () => {
  try {
    const response = await fetch(`${category_api}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

// Get all Users
export const getUsers = async () => {
  try {
    const condiation = `&`
    const response = await fetch(`${users_api}${PAGINATION}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

// Update sepcific user
export const updateUser = async (reqBody) => {
  try {
    const response = await fetch(updateUser_api, {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log(
      "Error while submitting feedback. Error in (submitFeedback) : ",
      error
    );
  }
};


// Get particular object from
export const getObjectByTitle = async (apiFor, title) => {
  const apiUrl = apiFor === 'theme' ? theme_by_title_api(title) : sub_theme_by_title_api(title)

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};


export const deleteFeedbackById = async (id) => {
  try {
    const response = await fetch(delete_answer_by_id__api(id), {
      method: "DELETE",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};