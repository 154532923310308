import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingSkeleton } from "../../components/common/LoadingSkeleton";
import Modal from "../../components/common/Modal";
import { TableSkeleton } from "../../components/common/TableSkeleton";
import Table from "../../components/History/Table";
import { sortArrByDate, sortArrByString } from "../../components/utility";
import Wizard from "../../components/Wizard";
import {
  ALERT_TYPE_ERROR,
  ALERT_TYPE_SUCCESS,
  BTN_NEWFEEDBACK_TITLE,
} from "../../constants";
import {
  deleteFeedbackById,
  getHistory,
  getObjectByTitle,
} from "../../service";
import Analytics from "../Feedback/Analytics";
import { getUser, clearUser } from "../../service/Auth";
import Card from "../../components/History/Card";
import { themeDefault } from "../../constants/themeSettings";
import RadarAnalytics from "../NewFeedback/RadarAnalytics";
import ToastMessage from "../../components/common/ToastMessage";
import Spinner from "../../components/common/Spinner";
import AppFooter from "../../components/common/AppFooter";

const HISTORY_COLUMNS = [
  {
    title: "date",
    sortType: "date",
  },
  {
    title: "Theme",
    sortType: false,
  },
  {
    title: "Subtheme",
    sortType: false,
  },
  /*  {
    title: "categories",
    sortType: false,
  }, */
  {
    title: "status",
    sortType: "string",
  },
  {
    title: "Actions",
    sortType: false,
  },
];

const LOADER_TYPE_TABLE = "tableSkeleton";
const LOADER_TYPE_SPINNER = "spinner";

const DeleteModal = () => {
  return (
    <div>
      <div className="flex justify-center">
        <div class="flex justify-center bg-white shadow rounded-md px-2 w-fit">
          <div class={`mr-6 rounded px-4 py-2  text-center -ml-3 bg-red-300`}>
            <svg
              class="h-8 w-8 text-red-500"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />{" "}
              <path d="M12 9v2m0 4v.01" />{" "}
              <path d="M5.07 19H19a2 2 0 0 0 1.75 -2.75L13.75 4a2 2 0 0 0 -3.5 0L3.25 16.25a2 2 0 0 0 1.75 2.75" />
            </svg>
          </div>
          <div class="flex items-center text-2xl font-bold mr-2 text-red-500">
            Delete
          </div>
        </div>
      </div>
      <p className="pt-5 font-bold text-base text-slate-400">
        Do you reallly want to delete this record? This process can't be undone.
      </p>
    </div>
  );
};

const HistoryIndex = () => {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [actionType, setActionName] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [showModal, setPopupOpen] = useState(false);
  const [isGraphModal, setGraphModal] = useState(false);
  const [isDeleteRecordModal, setDeleteRecordModal] = useState(false);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("");
  const [isLoaderType, setLoaderType] = useState(false); // check LoaderTypes
  const [alert, setAlert] = useState(null);

  const user = getUser();

  const navigateTo = (path) => navigate(path);

  useEffect(() => {
    fetchHistoryData();
    /*  showMapForSubmittedForms(); */
  }, []);

  const handleAlert = (alertValue) => {
    setAlert(alertValue);
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  // Show map/graph for submitted forms directly on /feedback page onload.
  const showMapForSubmittedForms = () => {
    const formData = location.state;
    if (formData) {
      setSelectedRow(formData);
      setGraphModal(true);
      // Clear prev loaded location state
      navigate(location.pathname, {});
    }
  };
  const handleSetSortOrder = (col) => {
    if (col.sortType) {
      const toggleOrder = sortOrder === "desc" ? "asc" : "desc";
      setSortOrder(toggleOrder);
      setSortColumn(col);
    }
  };

  // extract categories from feedback object and add it as an string array to each record
  const getHistoryTableFormattedData = (data) => {
    let columnBasedTableData = [];
    data.map((row) => {
      if (row.feedback) {
        for (const [key, value] of Object.entries(row.feedback)) {
          row.categories = Object.keys(value);
        }
      }

      return row;
    });
  };

  const fetchHistoryData = async (loaderType = LOADER_TYPE_TABLE) => {
    try {
      // Fetch history page data / api logic
      setLoaderType(loaderType);
      getHistory().then((data) => {
        // getHistoryTableFormattedData(data);
        if (!data.errorData) {
          const sortedData = sortArrByDate(data, "created");
          setHistory(sortedData);
        }
        setLoaderType(false);
      });
    } catch (err) {
      setLoaderType(false);
      console.log("Error ", err);
    }
  };

  const fetchObjectByTitle = async (btnType, value) => {
    try {
      // setLoaderType(true);
      // Fetch history page data / api logic
      getObjectByTitle(btnType, value).then((data) => {
        if (!data.errorData) {
          const resources_url = (data[0] && data[0]["resources_url"]) || "";
          if (resources_url) {
            window.open(resources_url, "_blank");
          } else {
            alert("No Resources found !");
          }

          setLoaderType(false);
        } else {
          // setError("Error while fetching data for selected feedback.");
        }
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };

  useEffect(() => {
    if (user === "null") {
      clearUser();
      navigateTo("/login");
      return;
    }
  }, [user]);

  const getFilteredData = () => {
    /* if (!searchText || searchText.length < 3) {
      return history;
    } */
    let filterData = history;
    if (searchText) {
      filterData = history.filter((obj) =>
        Object.keys(obj).some((key) => {
          if (key === "theme") {
            /* const filterGroup = Object.keys(obj[key]);
            return filterGroup.some((key) => {
              return key.toLowerCase().includes(searchText.toLowerCase());
            }); */
            return String(obj[key])
              .toLowerCase()
              .includes(searchText.toLowerCase());
          }
          if (key === "subTheme") {
            return String(obj[key])
              .toLowerCase()
              .includes(searchText.toLowerCase());
          }
          return String(obj[key])
            .toLowerCase()
            .includes(searchText.toLowerCase());
        })
      );
    }

    if (sortColumn && sortColumn.sortType === "string") {
      const sortByString = sortArrByString(
        filterData,
        sortColumn.title,
        sortOrder
      );
      return sortByString;
    } else if (sortColumn && sortColumn.sortType === "date") {
      const sortedData = sortArrByDate(filterData, "created", sortOrder);
      return sortedData;
    }
    return filterData;
  };

  const getFilteredDataCheck = () => {
    let filterData = history;
  };

  //search filter
  const onSearchChange = (e) => {
    if (e.target.value !== "") {
      setSearchText(e.target.value);
      /* setValue(e.target.value);
      const filterTable = history.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]); */
    } else {
      setSearchText("");
    }
  };

  const handleGraphClick = (data) => {
    setSelectedRow(data);
    setGraphModal(true);
  };

  const closeGraphModal = () => {
    setSelectedRow(null);
    setGraphModal(false);
  };

  // Open delete modal
  const handleDeleteFeedbackClick = (data) => {
    setSelectedRow(data);
    setDeleteRecordModal(true);
  };

  const closeDeleteRecordModal = () => {
    setSelectedRow(null);
    setDeleteRecordModal(false);
  };

  const handleConfirmDeleteRecordModal = () => {
    setDeleteRecordModal(false);
    deleteFeedbackApi(selectedRow);
  };

  const handleCloseToast = () => {
    setAlert(null);
  };

  // Handle On feedback record delete confirm
  const deleteFeedbackApi = (data) => {
    setLoaderType(LOADER_TYPE_SPINNER);

    try {
      // Fetch history page data / api logic
      deleteFeedbackById(data.objectId).then((data) => {
        if (!data.errorData) {
          handleAlert({
            message: "Record Deleted successfully!",
            type: ALERT_TYPE_SUCCESS,
          });
        } else {
          handleAlert({
            message:
              "Error occured while deleting record ! Please contact to admin.",
            type: ALERT_TYPE_ERROR,
          });
        }
        setLoaderType(false);
        fetchHistoryData(LOADER_TYPE_SPINNER);
      });
      setSelectedRow(null);
    } catch (err) {
      console.log("Error ", err);
      handleAlert({
        message:
          "Error occured while deleting record data ! Please contact to admin.",
        type: ALERT_TYPE_ERROR,
      });
    }
  };

  // Click Handlers
  const onRowClick = (data, actionType) => {
    setActionName(actionType);
    setSelectedRow(data);
    setPopupOpen(true);
  };

  const openModal = () => setPopupOpen(true);
  const closeModal = () => setPopupOpen(false);
  // Click Handlers

  console.log("history", history.length);

  const newFeedbackBtn = () => {
    return (
      <div
        className="sticky top-0 z-50"
        style={{
          display: "flex",
          margin: "auto",
          width: "400px",
          justifyContent: "center",
        }}
      >
        {history?.length < 1 ? (
          <button
            type="button"
            className={`text-white font-bold text-sm px-5 py-2.5 mr-2 mb-2 ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover} focus:outline-none`}
            onClick={() => navigate("/pillar")}
          >
            {BTN_NEWFEEDBACK_TITLE}
          </button>
        ) : null}
        {/*     <button
          type='button'
          className={`text-white font-bold text-sm px-5 py-2.5 mr-2 mb-2 ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover} focus:outline-none`}
          onClick={() => navigate("/pillar")}
        >
          {BTN_NEWFEEDBACK_TITLE}
        </button>  */}
      </div>
    );
  };

  const historyTable = () => {
    return (
      <>
        {newFeedbackBtn()}
        {!showModal && (
          <Card
            data={getFilteredData()}
            showActionBtn={true}
            actionType={["view", "edit"]}
            onRowClick={onRowClick}
            handleGraphClick={handleGraphClick}
            handleDeleteFeedbackClick={handleDeleteFeedbackClick}
            searchText={searchText}
            onSearchChange={onSearchChange}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            handleSetSortOrder={handleSetSortOrder}
          />
        )}
        {isGraphModal && (
          <Modal
            isOpen={isGraphModal}
            modalTitle={"VISUALISE YOUR PERFORMANCE ON SPORT VOLUNTEERING"}
            title={
              "Identify potential areas of improvement for your sport organisation"
            }
            subTitle={"[Topic]"}
            onClose={closeGraphModal}
            onConfirm={closeGraphModal}
            hideActionButtons={true}
            childComponent={
              <RadarAnalytics
                data={selectedRow}
                fetchObjectByTitle={fetchObjectByTitle}
              />
            }
          />
        )}
        {isDeleteRecordModal && (
          <Modal
            isOpen={isDeleteRecordModal}
            modalStyle={"max-w-2xl"}
            saveBtnStyle={"btn-error"}
            cancelBtnStyle={"btn-neutral"}
            // Red: Close button used to delete/confirm
            onClose={closeDeleteRecordModal}
            // Green: Confirm used to close modal
            onConfirm={handleConfirmDeleteRecordModal}
            hideActionButtons={false}
            cancelBtntext={"Cancel"}
            confirmBtntext={"Delete"}
            childComponent={<DeleteModal />}
          />
        )}
        {showModal && (
          <div>
            <button class="btn btn-outline" onClick={closeModal}>
              Back to History
            </button>
            <Wizard selectedFormData={selectedRow} />
            {/* <Modal
          isOpen={showModal}
          onClose={closeModal}
          onConfirm={closeModal}
          title={`${actionType.toString().toUpperCase()} Feedback`}
          childComponent={
            <Wizard selectedFormData={selectedRow}/>
          }
        /> */}
          </div>
        )}
        {alert && <ToastMessage alert={alert} closeToast={handleCloseToast} />}
      </>
    );
  };

  const showComponentBody = (childComponent) => {
    if (isLoaderType === LOADER_TYPE_SPINNER) {
      return (
        <>
          <div className="flex h-screen absolute -top-10 left-1/2 z-50">
            <Spinner />
          </div>
          {historyTable()}
        </>
      );
    } else if (isLoaderType === LOADER_TYPE_TABLE) {
      return <TableSkeleton />;
    }
    return <>{historyTable()}</>;
  };

  return (
    <div className="w-full flex flex-col justify-between h-screen">
      <div
        className={`w-full py-10 px-4 sm:px-6 lg:px-8 ${
          isGraphModal ? "fixed" : "relavtive"
        }`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {showComponentBody()}
      </div>
      {!isLoaderType && <AppFooter />}
    </div>
  );
};
export default HistoryIndex;
