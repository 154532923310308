import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { themeDefault } from "../../constants/themeSettings";
import { getTheme, getAttribute, updateFeedback } from "../../service";
import { sortDataMain } from "../../components/utility";
import { PILLAR_SELECT, WIZARD_ROUTE } from "../../constants/Routes";
import { CardLayoutLoader } from "../common/LoadingSkeleton";
import {
  MIN_PILLAR_SELECTED,
  PILLAR_TITLE_PREFIX,
  UPDATE_STATUS,
} from "../../constants";
import AppFooter from "../common/AppFooter";

const ERROR_MESSAGE = "Error while uploading your data. Please contact Admin.";
const SUBMIT_SUCCESS_MESSAGE = "Your answers have been submitted successfully!";
const SAVE_CONTINUE_SUCCESS_MESSAGE =
  "Your answers have been saved successfully!";

const NUMBER_OF_MIN_PILLARS = 7;
const stickyClassNextBtn = "fixed bottom-8 right-8 z-50";

const PillarSelectEdit = () => {
  const [themeData, setThemeData] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [isError, setError] = useState("");
  const [attribute, setAttribute] = useState([]);

  // Data is passed from feedback question Questionnaire page (on setting icon click)
  const { state } = useLocation();
  const { feedbackObject, isNewFeedback } = state;

  const disableClass = "disabled:cursor-not-allowed";

  const isMinPillarSelected = selectedTitles.length >= MIN_PILLAR_SELECTED;

  const navigate = useNavigate();

  const overRideData = (array1, array2) => {
    const updateData = array1.map(
      (a1) => array2.find((a2) => a1.sequence === a2.sequence) || a1
    );
    return updateData;
  };

  const fetchFormInitialAttribute = () => {
    getAttribute()
      .then((response) => {
        if (!response.errorData) {
          const data = feedbackObject && feedbackObject.feedback;
          const attributeData =
            data && data.filter((e) => e.___class === "AttributeTheme");
          if (attributeData) {
            const mergeData = overRideData(response, attributeData);
            setAttribute(mergeData);
          } else {
            setAttribute(response);
          }

          setLoader(false);
        } else throw new Error("Something went wrong");
      })
      .catch(() => {
        setLoader(false);
        setError(
          "Error occured while fetching Theme api data ! Please contact to admin."
        );
      });
  };

  const fetchFormInitialData = () => {
    getTheme()
      .then((response) => {
        if (!response.errorData) {
          const data = feedbackObject && feedbackObject.feedback;

          if (data) {
            const cardData = data && data.filter((e) => e.___class === "theme");
            const mergeData = overRideData(response, cardData);
            let sortedData = sortDataMain(mergeData);
            setThemeData(sortedData);
            setSelectedTitles(cardData);
          } else {
            let sortedData = sortDataMain(response);
            setThemeData(sortedData);
          }

          setLoader(false);
        } else throw new Error("Something went wrong");
      })
      .catch(() => {
        setLoader(false);
        setError(
          "Error occured while fetching Theme api data ! Please contact to admin."
        );
      });
  };

  const sortBySequence = (arr) => {
    let data = arr;
    data.sort((a, b) => a.sequence - b.sequence);
    return data;
  };

  useEffect(() => {
    setLoader(true);
    fetchFormInitialData();
    fetchFormInitialAttribute();
  }, []);

  const handleCheckboxChange = (title) => {
    if (selectedTitles.includes(title)) {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    } else {
      setSelectedTitles([...selectedTitles, title]);
    }
  };

  // Update form request to backend
  const onUpdate = (reqBody) => {
    // Update current form state to state
    // setFormData({ ...formData, ...reqBody });
    reqBody.status = UPDATE_STATUS;
    const objectId = reqBody.objectId;

    updateFeedback(reqBody, objectId)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          //   handleOnError();
        } else {
          // console.log(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
          // Update current form state to state
          //   handleOnSuccess(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
          navigate(`/feedback/${feedbackObject.objectId}`);
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        // handleOnError();
      });
  };

  // Pillar selection edit
  const handleExistingData = (sortedCards) => {
    feedbackObject.feedback = sortedCards;
    onUpdate(feedbackObject);
    // Clear the state after
    // navigate(`/${PILLAR_SELECT}`, { replace: true });

    // navigate(`/${WIZARD_ROUTE}`, { state: sortedCards });
    // navigate(`/feedback/${feedbackObject.objectId}`);
    // window.history.replaceState(null, "", `/feedback/${feedbackObject.objectId}`);
  };

  const handleContinueToFeedback = () => {
    let obj = attribute[0];
    let sortedCards = sortBySequence(selectedTitles);
    let check = sortedCards.push(obj);
    if (!isNewFeedback && Object.keys(feedbackObject).length) {
      handleExistingData(sortedCards);
    } else {
      navigate(`/${WIZARD_ROUTE}`, { state: sortedCards });
    }
  };

  const rightArrowIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        stroke="currentColor"
        class="w-4 h-4 pl-1"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
        />
      </svg>
    );
  };

  const continueToFeedbackBtn = () => {
    const iconBgColor = !isMinPillarSelected
      ? "from-slate-500 to-slate-600"
      : "from-green-500 to-green-600";

    const bgC = !isMinPillarSelected
      ? "bg-slate-300"
      : "bg-green-700 text-white";
    const tooltipClass = !isMinPillarSelected && "tooltip tooltip-left";

    return (
      <div
        className={`${stickyClassNextBtn} ${tooltipClass}`}
        // data-tip={"Please select at least 3 Activities to proceed!"}
      >
        <div className="relative group">
          <div
            className={`absolute -inset-1 bg-gradient-to-r ${iconBgColor} rounded-full blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200`}
          ></div>
          <button
            disabled={!isMinPillarSelected}
            className={`relative p-5 ${bgC} ring-8 ring-gray-900/5 rounded-full leading-none flex justify-start items-center text-sm font-bold space-x-6 ${
              !isMinPillarSelected && disableClass
            }`}
            onClick={handleContinueToFeedback}
          >
            Next step
            {rightArrowIcon()}
          </button>
        </div>
      </div>
    );
  };

  const PillarCard = ({ data }) => {
    const hoverStyle =
      "hover:opacity-100 transition-all duration-300 hover:text-white hover:font-medium hover:cursor-pointer";

    const isCardSelected = selectedTitles.includes(data);

    const cardSelectedStyle = isCardSelected
      ? "opacity-100 text-white font-medium"
      : "opacity-75";

    const imgUrl = data?.bgImageUrl;
    const cardStyle = isCardSelected ? "text-red-700 mt-4" : "text-green-700";

    return (
      <div
        className={`relative shadow-lg shadow-blue-500/50 rounded-lg outline-none ${hoverStyle} ${cardSelectedStyle} bg-cover bg-center hover:opacity-100 group`}
        style={{ backgroundImage: `url('${imgUrl}')` }}
        onClick={() => handleCheckboxChange(data)}
      >
        <div
          id={`card-${data.sequence}`}
          className={`h-64 flex flex-col rounded-md items-center justify-center bg-[#00000080]`}
        >
          <div className="absolute -right-2 -top-2 z-10">
            <input
              id="policy-privacy"
              name="policy-privacy"
              type="checkbox"
              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
              checked={selectedTitles.includes(data)}
              onChange={() => handleCheckboxChange(data)}
            />
          </div>

          <div className="flex items-center p-2">
            <div className="flex flex-col justify-betweens items-center py-6 text-white leading-relaxed w-72 h-44">
              <div data-placeholder className="mb-5 font-semibold">
                SPORT VOLUNTEERING
                <br />
                {`ROLE ${data.sequence}`}
              </div>
              <div className="min-h-14 font-extrabold tracking-wide">
                {data.title.toUpperCase()}
              </div>
            </div>
          </div>

          <div>
            <p
              className={`absolute inset-x-0 bottom-2 opacity-0 mx-8 group-hover:opacity-100 transition-all duration-200 bg-slate-100 text-sm font-bold ${cardStyle}`}
            >{`Click to ${isCardSelected ? "Remove" : "Select"}`}</p>
          </div>
        </div>
      </div>
    );
  };

  const handleSelectAll = (evt) => {
    const isChecked = evt.target.checked;
    // Set all tiles on check or remove all tiles on un-check
    if (isChecked) {
      setSelectedTitles([...themeData]);
    } else {
      setSelectedTitles([]);
    }
  };

  const selectAllChechkBox = () => {
    const isSelectAllEnable = selectedTitles.length === themeData.length;

    return (
      <div>
        <div className="flex justify-end mb-3">
          <div className="form-control  hover:bg-slate-100 text-slate-500 hover:text-sky-800">
            <label className="label cursor-pointer px-4">
              <span className="text-lg font-medium px-4">
                {isSelectAllEnable ? "Clear" : "Select"} All
              </span>
              <input
                type="checkbox"
                className="cursor-pointer h-5 w-5 accent-sky-700 bg-sky-700"
                checked={isSelectAllEnable}
                onChange={handleSelectAll}
              />
            </label>
          </div>
        </div>
      </div>
    );
  };

  const cardLoadingLayout = () => {
    return (
      <>
        {[...Array(NUMBER_OF_MIN_PILLARS)].map((e, i) => (
          <div>
            <CardLayoutLoader />
          </div>
        ))}
      </>
    );
  };

  const renderData = () => {
    return (
      <>
        {themeData.map((e, i) => (
          <PillarCard data={e} />
        ))}
        {selectAllChechkBox()}
        <div>{continueToFeedbackBtn()}</div>
      </>
    );
  };

  return (
    <div>
      <div className="w-full py-8 px-4 sm:px-6 lg:px-8 ">
        <div className="py-1 mb-1 sticky top-16 right-0 z-20 bg-gray-50">
          <h1
            className={`text-xl font-medium bg-gray-50 ${themeDefault.text_color_dark} py-2 sticky top-16 right-0 z-[3]`}
          >
            Select the sport volunteering role(s) you have been involved in or
            are involved in now
          </h1>
          <h1 className="mb-1">If you click on the Next Step button below, you can edit your previous answers.</h1>
          <span className="text-red-400 font-medium text-sm">
            {!isMinPillarSelected &&
              "* Please select at least 1 sport volunteering role to move to the next step of the self-reflection"}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-8 p-2 md:grid-cols-4 sm:grid-cols-3">
          {isLoading ? cardLoadingLayout() : renderData()}
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default PillarSelectEdit;
