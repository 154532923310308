const ActivityCards = (props) => {
  const {
    step,
    index,
    data = [],
    activeStepIndex = 0,
    handleOnStepClick,
    stepCompletPercentage,
    showAllTitles,
  } = props;

  if (!data || !data.length) return null;

  const hoverTans = "transform transition duration-500 hover:scale-105";
  const imgUrl = step?.bgImageUrl;

  // Return true for current selected step
  const isCurrentStep = (index) => {
    if (showAllTitles === true) {
      return index === activeStepIndex - 4;
    } else {
      return index === activeStepIndex;
    }
  };

  const handleOnClick = (stepIndex, stepData) => {
    handleOnStepClick(stepIndex, stepData);
  };

  const isPillarCompleted = (stepCompletPercentage) => {
    const isCompleted = stepCompletPercentage === 100;
    if (isCompleted) return "text-green-700";

    return "text-gray-300";
  };

  const cardBottomDot = () => {
    return (
      <span class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-600"></span>
      </span>
    );
  };

  const showProgress = () => {
    return (
      <>
        {stepCompletPercentage > 0 && (
          <div class="absolute right-2 top-0">
            <div
              className={`absolute right-[-5px] top-5 font-semibold text-xs text-white ${isPillarCompleted(
                stepCompletPercentage
              )}`}
            >
              {stepCompletPercentage}%
            </div>
            <svg
              class={`h-6 w-4 ${isPillarCompleted(stepCompletPercentage)}`}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              {" "}
              <polyline points="9 11 12 14 22 4" />{" "}
              <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
            </svg>
          </div>
        )}
      </>
    );
  };

  const isStepActive = isCurrentStep(index);

  const cardSelectedStyle = isStepActive
    ? "opacity-100 border-2 border-blue-600/80"
    : "opacity-75 text-slate-50";

  return (
    <>
      <div
        className={`my-1 relative ${cardSelectedStyle} rounded-lg  bg-cover bg-center hover:opacity-100 group`}
        onClick={() => handleOnClick(index, step)}
        style={{ backgroundImage: `url('${imgUrl}')` }}
      >
        <div
          className={`${hoverTans} rounded-lg flex flex-col items-center pt-3 h-32 w-40 shadow border p-2 cursor-pointer bg-[#00000080] ${
            isStepActive && "text-white"
          }`}
        >
          <h5 class="text-xs font-bold mb-3 mt-0">
            SPORT VOLUNTEERING
            <br />
            ROLE {step.sequence}
          </h5>
          <p class="text-xs font-semibold">{step.title}</p>
        </div>

        {isStepActive && (
          <div className="flex justify-center absolute left-0 right-0 -bottom-1.5">
            {cardBottomDot()}
          </div>
        )}

        {
          //TODO: Hide progress as its overlapping - Fix it in code refactore
          // showProgress()
        }
      </div>
    </>
  );
};
export default ActivityCards;
