import { themeDefault } from "../../constants/themeSettings";
import { ATTR_CARD_BG_IMG } from "../../images";

const AttributeCards = (props) => {
  const {
    step,
    index,
    data = [],
    activeStepIndex = 0,
    handleOnStepClick,
    stepCompletPercentage,
    isAttributeCardEnable,
  } = props;

  if (!data || !data.length) return null;

  const hoverTans = "transform transition duration-500 hover:scale-105";
  const imgUrl = step?.bgImageUrl || ATTR_CARD_BG_IMG;

  // Return true for current selected step
  const isCurrentStep = (index) => {
    return index === activeStepIndex;
  };

  const handleOnClick = (stepIndex, stepData) => {
    handleOnStepClick(stepIndex, stepData);
  };

  const isPillarCompleted = (stepCompletPercentage) => {
    const isCompleted = stepCompletPercentage === 100;
    if (isCompleted) return "text-green-700";
    return "text-gray-300";
  };

  const cardBottomDot = () => {
    return (
      <span class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-600"></span>
      </span>
    );
  };

  const showProgress = () => {
    return (
      <>
        {stepCompletPercentage > 0 && (
          <div class="absolute right-2 top-2">
            <div
              className={`absolute right-[-5px] top-5 font-semibold text-xs text-white ${isPillarCompleted(
                stepCompletPercentage
              )}`}
            >
              {stepCompletPercentage}%
            </div>
            <svg
              class={`h-6 w-4 ${isPillarCompleted(stepCompletPercentage)}`}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              {" "}
              <polyline points="9 11 12 14 22 4" />{" "}
              <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
            </svg>
          </div>
        )}
      </>
    );
  };

  const specialCard = (data) => {
    const tooltipClass =
      !isAttributeCardEnable && "tooltip tooltip-left cursor-not-allowed";

    return (
      <div
        className="w-full flex justify-center bg-cover bg-center"
        style={{ backgroundImage: `url('${imgUrl}')` }}
      >
        <div
          className={`py-1 cursor-pointer ${tooltipClass} text-white font-bold text-xl bg-[#00000080] w-full`}
          data-tip={`Please answer at least 1 question from each activity to enable Attributes Questionnaire`}
          onClick={() => handleOnClick(index, step)}
        >
          <div
            className={`${hoverTans} flex flex-col justify-center items-center p-2 h-24`}
          >
            {step.title}
          </div>
          {/* {cardBottomLine()} */}
          {/* {isCurrentStep(index) && (
            <div className="flex justify-center absolute left-0 right-0 bottom-0">
              {cardBottomDot()}
            </div>
          )} */}

          {
            //TODO: Hide progress as its overlapping - Fix it in code refactore
            // showProgress()
          }
        </div>
      </div>
    );
  };

  return specialCard();
};
export default AttributeCards;
